<template>
  <div id="chartCont">
    <div :ref="'chart'+transdata.chnum" class="echarts"> </div>
  </div>
</template>

<script>
import * as echarts from 'echarts/core';
import { BarChart, LineChart, PieChart, ScatterChart } from 'echarts/charts';
import {
  DatasetComponent,
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';

echarts.use([
  DatasetComponent,
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  BarChart,
  LineChart,
  PieChart, 
  ScatterChart,
  CanvasRenderer,
]);

export default {
  name: "chart",
  props: {                
    transdata: {},
  },
  data() {
    return{
      chartObj: null,
      remf: {}
    }
  },
  watch: {
    transdata: {
      handler () {
        let option = this.dataprepare(this.transdata);
        this.chartObj.clear();
        this.chartObj.resize();
        this.chartObj.setOption(option,true);
      },
      deep: true
    }
  },
  mounted(){
    let option = this.dataprepare(this.transdata,this.chartsize);
    this.chartObj = echarts.init(this.refm);
    this.chartObj.setOption(option,true);
    this.chartObj.clear();
    this.chartObj.setOption(option,true);
  },
  methods:{
    dataprepare (thisdata) {
      
      this.refm = this.$refs['chart' + this.transdata.chnum];
      let parentWidth = this.refm.offsetParent.offsetWidth;
      let chartWidth = 600;
      if(parentWidth < 900){
        chartWidth = 400;
      }else{
        chartWidth = 500;
      }
      let chartHeight = chartWidth * 0.6 + 75;
      this.refm.style.width = chartWidth + 'px';
      this.refm.style.height = chartHeight + 'px';
      

      let option = {
        dataset: {source: []},
        title: {},
        legend: {},
        xAxis: {},
        yAxis: {}, 
        series: [],  //
        color: ['#cc0000','#61a0a8','#ca8622','#303355','#FF4D70','#33843B','#c4ccd3','#546570','#CB6B48','#7A0099','#FBC02D'], 
        animation: false,
      };
      let intH = this.transdata.qstid % 11;
      let arrTmp = option.color.slice(intH).concat(option.color.slice(0,intH));
      option.color = arrTmp;
      let arrsymbol = ['circle','emptyrect','diamond','emptytriangle','emptycircle','rect','star','emptydiamond','triangle'];
      let strSep1 = '|';
      let strSep2 = ';';

      //dataset setup
      let arr1 = thisdata.para3.replace(/\r\n/, "\n").split(/\n/);
      for (let i = 0; i < arr1.length; i++)
      {
        let temp = arr1[i].split(strSep2);
        option.dataset.source[i] = new Array(temp.length);
        for (var j = 0; j < temp.length; j++)
        {
          option.dataset.source[i][j] = isNaN(Number(temp[j])) ? temp[j] : Number(temp[j]);
        }
      }

      // series data setup
      let arrsr = thisdata.para1.split(strSep1);
      for (let i = 0; i < arrsr.length; i++){
        let arrit = arrsr[i].split(strSep2);
        if (arrit.length == 4 && arrit[0] == 'BAR'){ 
          option.series.push({
            type: 'bar',
            silent: true,
            stack: arrit[1],
            barGap: arrit[2],
            barCategoryGap: arrit[3],
          })
        }else if(arrit.length == 2 && arrit[0] == 'BAR'){
          option.series.push({
            type: 'bar',
            silent: true,
            stack: arrit[1],
          })
        }else if(arrit.length > 0 && arrit[0] == 'SCA'){
          option.series.push({
            type: 'scatter',
            silent: true,
            symbol: arrsymbol[i % arrsymbol.length],
          })
        }else if(arrit.length > 6 && arrit[0] == 'PIE'){  //pie charts
          let strFmt = '';
          if(/NAM/.test(arrit[5])){ strFmt += '{b}' }
          if(/VAL/.test(arrit[5])){ strFmt += (strFmt==''?'':'\n')+'{@['+(i+1)+']}' }
          if(/PER/.test(arrit[5])){ strFmt += (strFmt==''?'':'\n')+'{d}%' }
          option.series.push({
            type: 'pie',
            silent: true,
            center: [arrit[1], arrit[2]],
            radius: [arrit[3], arrit[4]],
            label: {
              show: arrit[5] != ''  ? true : false,
              position: arrit[6] == 'IN' ? 'inside' : 'outside',
              formatter: strFmt,
            },
            encode: {
                value: i+1,
                itemName: 0
            },
          })
        }else if(arrit.length > 1 && arrit[0] == 'LIN'){  //line charts
          option.series.push({
            type: 'line',
            silent: true,
            smooth: arrit[1] == 'SMO' ? true : false,
            symbol: arrit[2] == 'NON' ? 'none' : 'circle',
          })
        }else if(arrit.length > 0 && arrit[0] == 'LIN'){  //line charts
          option.series.push({
            type: 'line',
            silent: true,
          })
        }else if(arrit.length > 0){
          option.series.push({
            type: 'line',
            silent: true,
          })
        }
      }
      // axis data setup
      let arrxy = thisdata.para2.replace(/\r\n/, '\n').split(/\n/);
      if (arrxy.length >= 2){ 
        let arrx = arrxy[0].split(strSep2);
        if (arrx.length >= 9){
          option.xAxis.show = true,
          option.xAxis.type = arrx[0] == 'CAT' ? 'category' : arrx[0] == 'TIM' ? 'time' : 'value',
          option.xAxis.name = arrx[1].replace(/_/, '\n'),
          option.xAxis.nameLocation = arrx[2] == 'END' ? 'end' : arrx[2] == 'STA' ? 'start' : 'center',
          option.xAxis.nameGap = arrx[3],
          option.xAxis.splitNumber = arrx[4],  
          option.xAxis.splitLine = {show: arrx[5] == 'SPL' ? true : false},
          option.xAxis.minorSplitLine = {show: arrx[6] == 'MSL' ? true : false}
          option.xAxis.axisLabel = {
            show: arrx[7] == 'NOL' ? false : true,
            rotate: arrx[8],
            interval: 0,
          }
          if (arrx.length >= 11){
            option.xAxis.min = arrx[9] == 'MIN' ? 'dataMin' : arrx[9];
            option.xAxis.max = arrx[10] == 'MAX' ? 'dataMax' : arrx[10];
          }
          option.xAxis.nameTextStyle = {fontWeight: 'bold',fontSize: 13}
          if(option.series[0].type == 'line'){
            if(option.series[0].symbol == 'none'){
              option.xAxis.boundaryGap = false;
            }
            option.xAxis.axisTick = {
              alignWithLabel: true,
            }
          }
        }else{
          option.xAxis.show = false
        }
        let arry = arrxy[1].split(strSep2);
        if (arry.length >= 9){
          option.yAxis.show = true,
          option.yAxis.type = arry[0] == 'CAT' ? 'category' : arry[0] == 'TIM' ? 'time' : 'value',
          option.yAxis.name = arry[1].replace(/_/, '\n'),
          option.yAxis.nameLocation = arry[2] == 'END' ? 'end' : arry[2] == 'STA' ? 'start' : 'center',
          option.yAxis.nameGap = arry[3],
          option.yAxis.splitNumber = arry[4],  
          option.yAxis.splitLine = {show: arry[5] == 'SPL' ? true : false}
          option.yAxis.minorSplitLine = {show: arry[6] == 'MSP' ? true : false}
          option.yAxis.axisLabel = {
            show: arry[7] == 'NOL' ? false : true,
            rotate: arry[8],
            interval: 0,
          }
          if (arry.length >= 11){
            option.yAxis.min = arry[9] == 'MIN' ? 'dataMin' : arry[9];
            option.yAxis.max = arry[10] == 'MAX' ? 'dataMax' : arry[10];
          }
          option.yAxis.nameTextStyle = {fontWeight: 'bold',fontSize: 14}
        }else{
          option.yAxis.show = false
        }
      }else{
          option.xAxis.show = false
          option.yAxis.show = false
      }

      //legend and title
      if (thisdata.para4 != ''){
        let arr4 = thisdata.para4.replace(/\r\n/, "\n").split(/\n/);
        if (arr4.length >= 2){ 
          //title and grid
          let arrtt = arr4[0].split(strSep2);
          if (arrtt.length >= 2 && arrtt[0]!=''){
            option.title.show = true;
            option.title.text = arrtt[0].replace(/_/, '\n'),
            option.title.left = arrtt[1] == '' ? 'center' : arrtt[1];
            option.title.textStyle = {fontWeight: 400};
          }
          if (arrtt.length >= 6){
            option.grid = {
              left: arrtt[2] == '' ? '50' : arrtt[2],
              right: arrtt[3] == '' ? '5' : arrtt[3],
              top: arrtt[4] == '' ? '60' : arrtt[4],
              bottom: arrtt[5] == '' ? '40' : arrtt[5],
            }
          }
          //legend
          let arrLg = arr4[1].split(strSep2);
          if (arrLg.length >= 1 && arrLg[0] == 'NOL'){
            option.legend.show = false;
          }else if (arrLg.length >= 3){
            option.legend.show = true;
            option.legend.orient = arrLg[0] == 'VER' ? 'vertical' : 'horizontal'; 
            if(arrLg[1]!=''){
              option.legend.left = arrLg[1];
            }
            if(arrLg[2]!=''){
              option.legend.top = arrLg[2];
            }
          }else{
            option.legend.top = 32;
          }
        }
      }
      option.legend.selectedMode = false;

      return option;
    },


  }
}
</script>

<style scoped>
#chartCont {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
