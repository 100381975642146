<template>
  <div id="app">
    <div id="load" v-if="loading">
      <img src="loading.gif" height="300">
    </div>
    <layer v-if="this.$store.state.logined" />
    <div id='popLogin' v-if="!loading && !this.$store.state.logined" >
      <login />
    </div>
  </div>
</template>

<script>
import { auth } from './utils/firebase.js';
import { onAuthStateChanged } from "firebase/auth";
import login from './components/login.vue';
import layer from './components/layer.vue';

export default {
  name: 'App',
  components: {
    layer,
    login,
  },
  data() {
    return{
      loading: true,
    }
  },
  created(){
    onAuthStateChanged(auth, (user) => {
      if (user) {
        this.$store.commit('setloginuser', user);
      }else {
        this.$store.commit('setloginuser', null);
      }
      this.loading = false;
    });
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-top: 0;
}
#load{
  display: flex;
  flex-direction: column;
  align-items: center;
}


</style>
