import axios from 'axios';
import { CONF } from './config.js'
import { auth } from './firebase.js';

//创建axios的一个实例 
var instance = axios.create({
    baseURL: CONF.axiosBaseUrl,
    timeout: CONF.axiosTimeout,
    maxContentLength: Infinity,
    maxBodyLength: Infinity,
})
 

//Axios请求拦截器
instance.interceptors.request.use(async function(config){
  let token = await auth.currentUser.getIdToken();
  if (token) {  // 判断是否存在token，如果存在的话，则每个http header都加上token
    config.headers.Authorization = token;
  }
  return config
}, error => {
  return Promise.reject(error)
})


export function Geter(url, data = null) {
  return(instance.get(url, { params: data }));
}

export function Deleter(url, data = null) {
  return(instance.delete(url, { params: data }));
}

export function Poster(url, data = null) {
  return(instance.post(url, data));
}

export function Puter(url, data = null) {
  return(instance.put(url, data));
}

