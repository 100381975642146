//import firebase from 'firebase/app';
//import 'firebase/firestore'
//import 'firebase/firebase-auth'
//import 'firebase/storage'
import { initializeApp } from 'firebase/app'
import { getAuth } from "firebase/auth";

// Your web app's Firebase configuration
var firebaseConfig = {
  apiKey: "AIzaSyBzxp7j9eWZncsbDmWT_INYoG3y_K9K7DA",
  authDomain: "ctedu20.firebaseapp.com",
  databaseURL: "https://ctedu20.firebaseio.com",
  projectId: "ctedu20",
  storageBucket: "ctedu20.appspot.com",
  messagingSenderId: "58567851693",
  appId: "1:58567851693:web:0c6ba533242f56c2ad9024"
};

 
const fbApp = initializeApp(firebaseConfig);
const auth = getAuth(fbApp);

export { auth }
