<template>
  <div>
    <a-modal
      title="Login to System Admin"
      v-model:visible="visible"
      :confirm-loading="confirmLoading"
      :maskClosable="false"
      :closable="false"
      :keyboard="false"
      :footer="null"
    >
    <a-input class="loginput" addon-before="Username" v-model:value="email" placeholder="input Email" @change="inputCheck" />
    <p><span class="warning">{{ promptEmail }}</span></p>
    <a-input-password class="loginput" addon-before="Password" v-model:value="password" placeholder="input password" @change="inputCheck" />
    <p><span class="warning">{{ promptPassword }}</span></p>
    <div :style="{display:'flex', justifyContent:'center'}">
      <a-button key="Login" type="primary" @Click="login">Click here to Login</a-button>
    </div>
    </a-modal>
  </div>
</template>


<script>
import { auth } from '../utils/firebase.js';
import { signInWithEmailAndPassword } from "firebase/auth";

export default {
  name: "Login",
  data() { 
    return {
      visible: true,
      confirmLoading: false,
      email:null, 
      password:null,
      promptEmail:'',
      promptPassword:'',
    }
  },
  methods:{ 
    inputCheck(){
      if(/^[-.\w]+@[-.\w]+\.[-.\w]+$/.test(this.email)){
        this.promptEmail = '';
      }else{
        this.promptEmail = "Email must be in valid format !";
      }
      if(/^(?![A-Z]+$)(?![a-z]+$)(?!\d+$)(?![\W_]+$)\S{6,16}$/.test(this.password)){
        this.promptPassword = '';
      }else{
        this.promptPassword = "Password must be 6-16 characters in length and contain at least 2 types among Uppercase, Lowercase characters or Digits !";
      }
    },
    login(){ 
      this.confirmLoading = true;
      signInWithEmailAndPassword(auth, this.email, this.password)
        .then((user) => {
          this.visible = false;
          this.confirmLoading = false;
          console.log(user);
        }) 
        .catch((error)=>{
        // Handle Errors here.
          let errorCode = error.code;
          //var errorMessage = error.message;
          if (errorCode === 'auth/wrong-password') {
            // 密碼錯誤提醒
            this.promptPassword = "Wrong Password !";
          } else {
            // 其他錯誤提醒
            this.promptEmail = "Wrong email address !";
          }
          console.log(error);
        });
    },

  },
}
</script>
 
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.loginput{
  font-family: monospace;
}
.warning {
 color: red;
}
</style>
