<template>
  <div id="load" v-if="putting" class="spinPop">
    <a-spin size="large" />
  </div>
  <div id="list-div" @click="doohis=''">

    <a-row v-for="(item) in quest_list_data" :key="item.cap_id+'-'+item.id" >
      <a-row v-if="item.sep" class="line-box" style="background-color:#fff;"> 
        <a-col span="12" style="padding: 4px 16px;">▲ Normal</a-col><a-col span="12" style="padding: 4px 16px;text-align:right;">▼ Pre</a-col>
      </a-row>
      <a-row class="line-box" @click="showCap(item.isquest,item.cap_id)" :id="'lbox'+item.id" :style="item.id==this.thisSel ? 'background-color:#B0BEC5':(item.belong=='SS'&&item.level<5)||(item.belong=='OC'&&item.level<4)?'background-color:#E9F9D0':(item.belong=='SS'&&item.level>=8)||(item.belong=='OC'&&item.level>=7)?'opacity:0.5;':''">
        <a-col span="1" v-if="item.isquest ? false : true" >
          <div style="height: 113px;text-align:center; background-color: orange;">
            C<br>
            A<br>
            P<br>
            <b>{{item.capnum}}</b>
          </div>
        </a-col>
        <a-col span="1" v-if="item.isquest && item.cap_id>0" >
          <div style="height: 64px; border-width: 20px; border-style: solid; border-color:orange transparent transparent transparent;">
            <br>
            <br>
          </div>
        </a-col>
        <a-col span="2" v-if="item.list_pic">
          <div style="margin: 7px 2px;text-align:center;">
            <img :src="item.list_pic" height="50" width="50" style="border-radius: 4px;"/>
          </div>
        </a-col>
        <a-col :span="getcntspan(item)">
          <div style="margin: 1px 16px 1px 4px;text-align:justify;max-height:110px;overflow:hidden;">
            <a-checkbox v-if="showCheck" v-model:checked="arrChecked[item.id]" @change="countChange()"></a-checkbox>  
            <a v-if="item.isquest" @click="sorter('ID')">[{{ item.id }}{{(sortBy=='ID' || sortBy=='')?'▲':''}}]</a>
            <span v-if="!item.isquest">[{{ item.cap_id }}]</span>
            <span v-if="!item.isquest && /##[ \w]*A *\n/.test(item.quest_content)" style="margin-left:6px;color:yellow;background-color:darkblue;">[ A & B ]</span>
            <span v-if="item.isquest && (item.mainskill!=$store.state.listskill.sk || item.belong!=$store.state.listskill.bl)" style="background-color:#B0BEC5;">
              {{'['+item.belong+' ⇾ '+$store.state.skillcasc[item.mainskill]+']' }}
            </span> 
            <span :class="item.similar==this.thisSimi?'simiSpan':'simiGrey'" v-if="item.similar" @click="sorter('SM')">{{ item.simiNum+'╱'+item.simiCount+'★'+item.similar }}{{(sortBy=='SM')?'▲':''}}</span>
            <span style="background-color:#F6AAC0;">
            {{ item.notes }}
            </span>
            {{ item.quest_content }}
          </div>
        </a-col>
        <a-col span="2" v-if="item.capnum" style="text-align:center;">
          <span :style="capcalc[item.capnum-1].acts==capcalc[item.capnum-1].quests?'background-color: orange;padding: 2px 4px; border-radius: 2px;':''">{{ capcalc[item.capnum-1].quests }}qs</span>
          <span v-if="capcalc[item.capnum-1].acts>0 && capcalc[item.capnum-1].quests>capcalc[item.capnum-1].acts" style="background-color: orange;padding: 2px 4px; border-radius: 2px;">-{{capcalc[item.capnum-1].acts}}</span><br>
          ▼<br>
          T {{ Math.round(capcalc[item.capnum-1].secs) }}<br>
          {{ Math.round(capcalc[item.capnum-1].accu*100) }}%<br>
          {{ capcalc[item.capnum-1].atts }}<br>
        </a-col>
        <a-col span="2" v-if="item.isquest" style="text-align:center;">
          <a @click="sorter('LV')">L{{ item.level }}{{sortBy=='LV'?'▽':''}}</a> T{{ item.avgtime }}<br>
          S {{ item.splits }}<br>
          <a-drawer
            title="Students Selctions"
            placement="left"
            :closable="true"
            :mask="false"
            :width="240"
            :visible="doohis!=''"
            @close="doohis=''"
          >
            <p v-for="(item) in doohis" :key="'DT'+item.id">{{item.ans}}{{item.ass==1?'✅':item.ass==4?'❌':'-'+item.ass}}【{{Math.round(item.avg5)}}】 {{item.dur}}s | {{item.str}}</p>
          </a-drawer>              
          <a @click="sorter('AC')">{{ Math.round(item.accuracy*100) }}%{{sortBy=='AC'?'▲':''}}</a><br>
          <a v-if="item.isquest" @click="showdoohis(item.id)">{{ item.attendees }}</a><br>
        </a-col>
        <a-col span="2" v-if="item.isquest" style="text-align:center;">
          <a @click="actioner(item.id,item.cap_id,item.similar,'Show')">Show</a><br>
          <a-popconfirm v-if="item.mainskill==$store.state.listskill.sk" placement="left" ok-text="Yes" cancel-text="No" @confirm="deactive(item.id)">
            <template v-slot:title>
              <p>Are you sure you want to deactive this question?</p>
              <p>You can edit this question after that.</p>
            </template>
            <a v-if="item.active==1" class="deacLink">DeAc</a>
          </a-popconfirm>
          <a @click="actioner(item.id,item.cap_id,item.similar,'Edit')" v-if="item.active==0 && item.mainskill==$store.state.listskill.sk">Edit</a><br>
          <a @click="actioner(item.id,item.cap_id,item.similar,'Copy')">Copy</a><br>
          <a v-if="item.mainskill!=$store.state.listskill.sk || item.belong!=$store.state.listskill.bl" @click="jumper(item.id,item.belong,item.mainskill,item.similar)">Jump</a><br>
        </a-col>
      </a-row>
    </a-row>

    <a-affix :offset-bottom="4">
      <div id='act_div'>
        <div id='in_act_div'>
          SimiEdit <div class="sc_gap"> </div> 
          <a-switch :checked="showCheck"  @change="showCheckChange()" /> <div class="sc_gap"> </div>
          {{intChecked>0 ? intChecked+' Selected':''}} <div class="sc_gap"> </div> 
          <a-button v-if="intChecked" size="small" @click="simiSet(1)" :loading="putting">Set Simi</a-button> <div class="sc_gap"> </div>
          <a-button v-if="intChecked" size="small" @click="simiSet(0)" :loading="putting">Clear</a-button>
        </div>
        <div id='in_act_div'>
          <div style="width:100%;text-align:center;background-color:#607D8B;color:white;"><b>{{quest_list_data.length}} items</b></div> 
        </div>
        <div id='in_act_div' >
          <a-input v-model:value="searchWord" size="small" style="width:180px;" /><div class="sc_gap"> </div> 
          <a-button type="primary" size="small" @click="searchAct">Search</a-button>
        </div>
      </div>
    </a-affix>
  </div>

</template>

<script>
import {Geter,Puter} from '../utils/request.js';
import swal from 'sweetalert2';
window.Swal = swal;

export default {
  name:'questlist',
  components: {
    //DownOutlined,
  },

  data () {
    return {
      resdata: [],
      quest_list_data: [],
      thisSel: -1,
      thisCap: 0,
      thisSimi: 0,
      showCheck: false,
      arrChecked: [],
      intChecked: 0,
      putting: false,
      searchWord: '',
      doohis: '',
      sortBy: '',
      capcalc: [],
    };
  },

  mounted() {
    if(!this.$store.state.listskill.sk){
      if(localStorage.getItem('localskill')){
        let localskill = JSON.parse(localStorage.getItem('localskill'));
        this.$store.commit('setlistskill', localskill);
      }
    }else{
      this.refreshList();
    }
  },

  methods:{
    async getList(skill,belong,search=''){
      this.putting = true;
      await Geter(`questlist`,{'sk':skill,'bl':belong,'sr':search})
      .then(res => {
        this.resdata = res.data;
        this.quest_list_data = [];
        this.capcalc = [];  
        if(this.resdata[0].cap_id>0){ //有cap
          let capnum = 1;
          let capquests = 0;
          let capsecs = 0;
          let capaccu = 0;
          let capatts = 0;
          let capacts = 0; // cap里active的有多少个

          for(let i = 0; i < this.resdata.length;i++){
            if(this.resdata[i].id==0){  // 说明是cap行
              this.resdata[i].capnum = capnum;
              this.quest_list_data.push(this.resdata[i]);
              if(capnum>1){
                this.capcalc.push({quests:capquests,acts:capacts,secs:capsecs,accu:capaccu,atts:capatts});
                capquests = 0;
                capacts = 0;
                capsecs = 0;
                capaccu = 0;
                capatts = 0;
              }
              capnum++;
            }else{
              capaccu = (capaccu * capquests + this.resdata[i].accuracy) / (capquests + 1);
              capsecs = (capsecs * capquests + this.resdata[i].avgtime) / (capquests + 1);
              capquests++;
              capacts += this.resdata[i].active > 0 ? 1: 0;
              capatts = this.resdata[i].attendees;
              if(this.resdata[i].cap_id==this.thisCap){  // 被选中才显示
                this.quest_list_data.push(this.resdata[i]);
              }
            }
          }
          this.capcalc.push({quests:capquests,acts:capacts,secs:capsecs,accu:capaccu,atts:capatts});
        }else{
          this.quest_list_data = res.data;
        }
        if(!this.searchWord) this.dupliCheck();
      })
      .catch(err => {
        console.log(err);
      });
      await this.sorter(this.sortBy);
      this.putting = false;
      this.arrChecked = [];
      this.intChecked = 0;
    },

    async refreshList(){
      if(Object.keys(this.$store.state.listskill).length){
        await this.getList(this.$store.state.listskill.sk,this.$store.state.listskill.bl);
        if(this.thisSel>=0 && this.$store.state.list2pos){
          this.scorllToDiv('lbox'+this.thisSel,-300);
        }
      }
    },

    searchAct(){
      if(this.searchWord){
        this.getList('','',this.searchWord);
        scrollTo(0,0);
      }
    },

    dupliCheck(){
      let arrAllWords = [];
      let arrDupli = [];
      for(let i=0;i<this.quest_list_data.length;i++){
        let arrMatch=[];
        if(this.quest_list_data[i].isquest){
          arrMatch = this.quest_list_data[i].quest_content.toLowerCase().match(/[a-z]{6,}/ig);
        }else{
          arrMatch = this.quest_list_data[i].quest_content.substring(160).toLowerCase().match(/[a-z]{6,}/ig);
        }
        let arrLong10 = [...new Set(arrMatch)].sort(function(a,b){
          return b.length - a.length;
        }).slice(0,12);
        for(let k=0;k<arrLong10.length;k++){
          // 长词,id,simi号
          arrAllWords.push({wd:arrLong10[k],cap:this.quest_list_data[i].cap_id,id:this.quest_list_data[i].cap_id+'-'+this.quest_list_data[i].id,sm:this.quest_list_data[i].similar?this.quest_list_data[i].similar:0});
        }
      }
      arrAllWords.sort(function (s1, s2) {
        if (s1.wd < s2.wd) {
            return -1;
        }
        if (s1.wd > s2.wd) {
            return 1;
        }
        return 0;
      });
      for(let j=1;j<arrAllWords.length;j++){
        // 查 // 长词相同，id不同且不simi
        if(arrAllWords[j].wd==arrAllWords[j-1].wd && !(arrAllWords[j].sm!=0 && arrAllWords[j].sm==arrAllWords[j-1].sm) && !(arrAllWords[j].cap==arrAllWords[j-1].cap && arrAllWords[j-1].id==''+arrAllWords[j].cap+'-0')){
          arrDupli[arrAllWords[j-1].id+'~'+arrAllWords[j].id]=arrDupli[arrAllWords[j-1].id+'~'+arrAllWords[j].id]?arrDupli[arrAllWords[j-1].id+'~'+arrAllWords[j].id]+1:1;
        }
      }
      let strDupli = '';
      for (var index in arrDupli) { 
        if(arrDupli[index]>6){
          strDupli = strDupli + index + '; ';
        }
      }
      if(strDupli){
        swal.fire({title: "Duplicate reminder",
                  text: strDupli,
                  icon: "warning",
                  showConfirmButton: true});
      }
    },

    async sorter(byw){
      this.sortBy = byw;
      if(byw=='LV'){
        this.quest_list_data.sort(function(a, b){
          let alvl = 0;
          let blvl = 0;
          if((a.belong=='SS' && a.level<5) || (a.belong=='OC' && a.level<4)){
            alvl = 100000000;
          }
          if((b.belong=='SS' && b.level<5) || (b.belong=='OC' && b.level<4)){
            blvl = 100000000;
          }         
          let cal = alvl - blvl + a.capsort - b.capsort + (b.level - a.level)*10000;
          return cal;
        });
      }else if(byw=='AC'){
        this.quest_list_data.sort(function(a, b){
          let alvl = 0;
          let blvl = 0;
          if((a.belong=='SS' && a.level<5) || (a.belong=='OC' && a.level<4)){
            alvl = 100000000;
          }
          if((b.belong=='SS' && b.level<5) || (b.belong=='OC' && b.level<4)){
            blvl = 100000000;
          }         
          let cal = alvl - blvl + a.capsort - b.capsort +  (a.accuracy - b.accuracy)/10;
          return cal;
        });
      }else if(byw=='SM'){
        this.quest_list_data.sort(function(a, b){
          let alvl = 0;
          let blvl = 0;
          if((a.belong=='SS' && a.level<5) || (a.belong=='OC' && a.level<4)){
            alvl = 100000000;
          }
          if((b.belong=='SS' && b.level<5) || (b.belong=='OC' && b.level<4)){
            blvl = 100000000;
          }         
          let cal = alvl - blvl + (a.similar - b.similar) + (a.id - b.id)/1000000;
          return cal;
        });
      }else if(byw=='ID'){
        this.quest_list_data.sort(function(a, b){
          let alvl = 0;
          let blvl = 0;
          if((a.belong=='SS' && a.level<5) || (a.belong=='OC' && a.level<4)){
            alvl = 100000000;
          }
          if((b.belong=='SS' && b.level<5) || (b.belong=='OC' && b.level<4)){
            blvl = 100000000;
          }         
          let cal = alvl - blvl + a.capsort - b.capsort + (a.id - b.id)/1000000;
          return cal;
        });
      }
      for(let i = 0; i < this.quest_list_data.length;i++){
        if(this.quest_list_data[i].belong=='SS' && i>0 && this.quest_list_data[i].level<5 && this.quest_list_data[i-1].level>=5){
          this.quest_list_data[i].sep = 1;
        }else if(this.quest_list_data[i].belong=='OC' && i>0 && this.quest_list_data[i].level<4 && this.quest_list_data[i-1].level>=4){
          this.quest_list_data[i].sep = 1;
        }else{
          this.quest_list_data[i].sep = 0;
        }
      }
    },

    async deactive(getid){
      this.putting = true;
      await Puter(`questone`,{ 'id': getid, 'act': '0' })
      .then(res => {
        console.log('Clicked deactive',res.data);
      })
      .catch(err => {
        console.log(err);
        this.putting = false;
      });
      await this.refreshList();
      this.scorllToDiv('lbox'+getid,-300);
      this.thisSel = getid;
      this.putting = false;
    },

    showCap(isquest,capid){
      if(!isquest){
        this.quest_list_data = [];
        this.thisCap = capid;
        for(let i = 0; i < this.resdata.length;i++){
          if(this.resdata[i].id==0){  // 说明是cap行
            this.quest_list_data.push(this.resdata[i]);
          }else if(this.resdata[i].cap_id==this.thisCap){  // 被选中才显示
            this.quest_list_data.push(this.resdata[i]);
          }
        }
      }
    },

    async actioner(getid,capid,similar,type){
      this.putting = true;
      Geter(`questone`,{ 'id': getid })
      .then(res => {
        console.log('Clicked actioner',res.data);
        let objTmp = JSON.parse(JSON.stringify(res.data));
        if(type=='Copy'){//copy
          objTmp.id = 0;
        }
        this.$store.commit('setshowdata',objTmp);  //all
        if(type!='Show'){//edit or copy
          let newdata = JSON.parse(JSON.stringify(objTmp));
          this.$store.commit('seteditdata',newdata); 
        }else{ // show
          this.thisSel = getid;
          this.thisCap = capid;
          this.thisSimi = similar;
          this.clearData("Edit");
        }
        this.putting = false;
      })
      .catch(err => {
        console.log(err);
        this.putting = false;
      });
      if(type!='Show'){//edit
        this.thisSel = getid;
        this.thisCap = capid;
        this.thisSimi = similar;
        this.scorllToDiv('list-div',0);
      }
      this.doohis = '';
    },

    jumper(getid,belong,mainskill,similar){
      this.thisSel = getid;
      this.$store.commit('setlistskill', {sk:mainskill,bl:belong});
      this.actioner(getid,similar,'show')
    },

    adder(){
      this.clearData();
      this.scorllToEdit();
    },

    clearData(target="All"){
      let objTmp = {};
      objTmp.type_id = 2;
      objTmp.show_type = 2;
      objTmp.belong = this.$store.state.listskill.bl;
      objTmp.mainskill = parseInt(this.$store.state.listskill.sk);

      objTmp.id = '';
      objTmp.cap_id = '';
      objTmp.dsp_items = [];
      objTmp.act_items = [];
      objTmp.quest_content = '';
      objTmp.cap_content = '';
      objTmp.resolve = '';
      objTmp.otherskill = 0;
      objTmp.tricks = '';
      if(target=='Edit' || target=='All'){
        this.$store.commit('seteditdata',objTmp);
      }
      if(target=='All'){
        let newdata = JSON.parse(JSON.stringify(objTmp));
        this.$store.commit('setshowdata',newdata);
        this.thisSel = -1;
      }
    },

    scorllToDiv(botdiv,offs){
      let element = document.getElementById(botdiv);
      if(element){
        let actualTop = element.offsetTop; 
        let current = element.offsetParent;
        while (current !== null){
          actualTop += current.offsetTop;
          current = current.offsetParent;
        }
        let actualBot = actualTop+element.offsetHeight;
        scrollTo(0,actualBot+offs);        
      }

    },

    getcntspan(item){
      let intSpan = 24;
      if(!item.isquest || item.cap_id>0){ //for under cap
        intSpan = intSpan - 1;
      }
      if(item.isquest){ // for show and herf
        intSpan = intSpan - 4;
      }
      if(item.list_pic){  // for pic
        intSpan = intSpan - 2;
      }
      if(item.capnum){ // for show capcalc
        intSpan = intSpan - 2;
      }
      return(intSpan);
    },

    countChange(){
      this.intChecked = 0;
      this.arrChecked.forEach((list)=>{
        if(list){
          this.intChecked++;
        }
      })
    },

    showCheckChange(){
      this.showCheck=!this.showCheck;
      if(!this.showCheck){
        this.arrChecked=[];
      }
      this.countChange();
    },

    simiSet(type){
      this.putting = true;
      let similars=[];
      this.arrChecked.forEach((list,key)=>{
        if(list){
          similars.push(key);
        }
      }) 
      console.log(similars);  
      if(type){ // set similar
        Puter(`questlist`,{ 'sims': similars, 'act': 1 })
        .then(res => {
          console.log('set similar return',res.data);
          this.refreshList();
        })
        .catch(err => {
          console.log(err);
        });
      }else{ // Clear
        Puter(`questlist`,{ 'sims': similars, 'act': 0 })
        .then(res => {
          console.log('clear similar return',res.data);
          this.refreshList();
        })
        .catch(err => {
          console.log(err);
        });      
      }
      this.arrChecked = [];
      this.intChecked = 0;
      setTimeout(() =>{
        this.putting = false;
      },1000);
    },

    async showdoohis(qid){
      this.putting = true;
      Geter(`doodetail`,{'qid':qid})
      .then(res => {
        let doohisdata = res.data;
        let tmpDooHis = [];
        for(let i=0;i<doohisdata.length;i++){
          let arrAct = doohisdata[i].act_ans?doohisdata[i].act_ans.split('|'):[];
          let arrAns = doohisdata[i].stu_ans?doohisdata[i].stu_ans.split('|'):[];
          let arrOpt = doohisdata[i].act_option?doohisdata[i].act_option.split('|'):[];
          let arrStr = doohisdata[i].act_str.split('|');
          let thisOpt = '';
          let thisStr = '';
          let thisAct = '';
          for(let j=0;j<arrAns.length;j++){
            if(arrAns[j]=='Y'){
              thisOpt = arrOpt[j];
              thisStr = arrStr[j];
            }
            if(arrAct[j]=='Y'){
              thisAct = arrOpt[j];
            }
          }
          if(thisOpt!=''){
            tmpDooHis.push({act:thisAct,ans:thisOpt,ass:doohisdata[i].assess,dur:doohisdata[i].duration,str:thisStr,avg5:doohisdata[i].avg5})
          }
        }
        this.doohis = tmpDooHis;
        this.putting = false;
      })
      .catch(err => {
        console.log(err);
        this.putting = false;
      });
    },

  },

  watch: {
    '$store.state.listskill': function () {
      this.refreshList();
      scrollTo(0,0);
    },

    '$store.state.listchanged': function () {
      this.refreshList();
    },
  }
}

</script>

<style lang="less" scoped>
.ant-avatar-lg {
    width: 88px;
    height: 88px;
    line-height: 88px;
}

.list-item-meta {
    color: rgba(0, 0, 0, .45);
    display: inline-block;
    vertical-align: middle;
    font-size: 14px;
    margin-left: 120px;
    span {
        line-height: 20px;
    }
    p {
        margin-top: 2px;
        margin-bottom: 0;
        line-height: 14px;
    }
}

#list-div {
  margin: 8px 0 0 8px;
}
#act_div {
  margin: 2px;
  border-width: 1px;
  border-style: solid; 
  border-color: grey; 
  background-color:#ECEFF1;
  border-radius: 4px; 
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
#in_act_div {
  padding: 4px 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 1;
}
#in_act_div:not(:first-child){
  border-left: 1px solid grey;
}
#in_act_div:last-child{
  justify-content: flex-end;
}
.sc_gap {
  margin-right: 8px;
}

.spinPop{
  position:fixed;
  top: 30%;
  left: 40%;
  padding: 28px 28px 22px 28px;
  border-radius: 50px; 
  z-index: 99;
  background-color: rgb(247, 218, 218);
}

.line-box {
  margin: 2px;
  width: 100%;
  border-width: 1px;
  border-style: solid; 
  border-color: gray;  
  border-radius: 4px; 
}

.simiSpan {
  background-color: orange;
  margin: 0 4px;
  padding: 0 4px;
}
.simiGrey {
  background-color: #f1cfb2;
  margin: 0 4px;
  padding: 0 4px;
}

.deacLink {
  color: #999;
  background-color: orange;
  padding: 2px 4px;
  border-radius: 4px; 
}

</style>
