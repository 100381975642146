<template>
  <div class="userlist">
    <div id="usernotes" v-show="shownotes">
      <b>ID:</b>{{noteitem.id}}&nbsp;
      <span @dblclick="chgLang(noteitem.id,noteitem.idx)" v-if="noteitem.lang==1" style="font-size:12px;color:black;background-color: pink;border:3px pink solid;">CN</span>
      <span @dblclick="chgLang(noteitem.id,noteitem.idx)" v-if="noteitem.lang==2" style="font-size:12px;color:white;background-color: blue;border:3px blue solid;">EN</span>
      {{noteitem['student.plain']?'🅿️':''}}
      {{noteitem['student.other']?'🌙':''}}
      &nbsp;<b>R:</b>{{noteitem.created_at?new Date(noteitem.created_at).toLocaleDateString():''}}
      &nbsp;<b>B:</b>{{noteitem.buy_at?new Date(noteitem.buy_at).toLocaleDateString().replace(/01\/01\/1980/,' --'):''}}
      &nbsp;<b>V:</b>{{noteitem.visit_at?new Date(noteitem.visit_at).toLocaleDateString():''}}
      &nbsp;<b>P:{{noteitem.credit}}</b>
      &nbsp;<span @dblclick="chgNote(1)" style="font-size:13px;color:gray;background-color: #fad7a0;border:3px #fad7a0 solid;"> ♣</span>
      &nbsp;<span @dblclick="chgNote(2)" style="font-size:13px;color:gray;background-color: #fad7a0;border:3px #fad7a0 solid;">】</span> 
      <br><span style="background-color: #ddd;">{{noteitem.name}}</span>&nbsp;
      {{noteitem.email}}&nbsp;
      <span style="background-color: #ddd;">{{noteitem.mobile}}</span>
      {{noteitem.address}} {{noteitem.suburb}}
      <br><span style="background-color: #ddd;">{{noteitem['student.name']}}</span>
      {{noteitem['student.block']==4?'⛔':noteitem['student.block']==3?'⭕':noteitem['student.block']==2?'🔒🔒':noteitem['student.block']==1?'🔒':''}}
      {{noteitem['student.alert']>2?('❗'+arrNums[noteitem['student.alert']]+'❗'):noteitem['student.alert']==2?'❗❗':noteitem['student.alert']==1?'❗':noteitem['student.alert']==(-1)?'🌵':''}}
      <span @dblclick="mock(noteitem['student.fbuid'])">{{noteitem['student.loginname']}}</span>
      {{Math.floor(noteitem['student.pall']/100%10)>0?' ❰pc'+Math.floor(noteitem['student.pall']/100%10)+'❱':''}}
      {{Math.floor(noteitem['student.pall']/1000%10)>0?' ❰oc'+Math.floor(noteitem['student.pall']/1000%10)+'❱':''}}
      {{Math.floor(noteitem['student.pall']/10000%10)>0?' ❰ps'+Math.floor(noteitem['student.pall']/10000%10)+'❱':''}}
      {{Math.floor(noteitem['student.pall']/100000%10)>0?' ❰ss'+Math.floor(noteitem['student.pall']/100000%10)+'❱':''}}
      {{Math.floor(noteitem['student.pall']%100)>0?' ❰rt'+Math.floor(noteitem['student.pall']%100)+'❱':''}}
      &nbsp;&nbsp;<span style="background-color: #ddd;">{{noteitem['student.gender']}}</span>
      &nbsp;&nbsp;<b>Yr</b>{{noteitem['student.grade']}}
      <span v-if="noteitem['student.doidt']==2">⛺</span>
      &nbsp;&nbsp;<b>T:</b>{{new Date(noteitem['student.tested_at']).toLocaleDateString()}}
      &nbsp;&nbsp;<b>⭐{{noteitem['student.score']}} / {{noteitem['student.avg5']}}</b>
      <a-textarea v-model:value="noteitem.notes" :rows="8" style="margin-bottom:10px;resize: none;" />
      <a-button size="small" type="primary" @click="upNotes(1)" style="margin-right:10px;">Update</a-button>
      <a-button size="small" @click="upNotes(0)" style="margin-right:10px;">Cancel</a-button>
      <span v-if="Object.keys(location).length" style="color: #666;">&nbsp;&nbsp;<b>IP</b>: {{ noteitem.ip }} - {{ location.city }} -  
      {{ location.country_name=='Australia'?location.region_code:location.country_name}} - {{ location.org }}</span> 
    </div>      
    <div class="pageline">
      <div @click="onChange(item)" v-for="(item) in arrPageNum" :key="'pg'+item" 
       :class="(currentpage==item)?'pagetab bk_selpg':'pagetab'">{{item}}
      </div>
    </div>
    <div class="pageline">
      {{totalitems>0?totalitems:''}}&nbsp;
      <a-input v-model:value="iptsearch" size="small" style="width: 120px;margin-right:10px;"/>
      <a-radio-group v-model:value="valuechk">
        <a-radio value="s">Stu</a-radio>
        <a-radio value="e">Ema</a-radio>
        <a-radio value="m">Mob</a-radio>
        <a-radio value="a">Add</a-radio>
        <a-radio value="u">Sub</a-radio>
        <a-radio value="i">ID</a-radio>
        <a-radio value="n">Nts</a-radio>
      </a-radio-group>
      <a-button size="small" type="primary" @click="search(1)" style="margin-right:10px;">Search</a-button>
      <a-button size="small" @click="search(0)" style="margin-right:10px;">Clear</a-button>
    </div>    
    <table class="tb88">
      <tr style="text-align:center;font-size:15px;font-weight:bold;">
        <td></td><td>ID</td><td>Registed</td><td>Name</td><td>Email Add</td><td>Notes</td>
        <td>Student</td><td>Gender</td><td>Grade</td><td>Tested_at/ Viewed_at</td><td>Score</td><td>Avg</td>
        <td>Mobile</td><td>Address</td><td>Suburb</td><td>Credit</td><td>Buy_at</td>
        <td>Visit_at</td>
      </tr>
      <tr 
        :style="(item['student.pall']>0)?'background-color:#FFECB3;':''"
        v-for="(item,index) in users" :key="'or'+index">
        <td>{{(index%5==4 && index<99)?index+1:''}}</td> 
        <td>{{item.id}}</td>        
        <td>{{item.created_at?new Date(item.created_at).toLocaleDateString():''}}&nbsp; {{item.created_at?new Date(item.created_at).toLocaleTimeString():''}}</td>
        <td>{{item.name}}
          <span v-if="item.lang==2" style="white-space:nowrap;font-size:12px;color:white;background-color: blue;border:3px blue solid;">EN</span>
        </td>
        <td>{{item.email}}{{item['student.clone']?'\n🆎':''}}{{item['student.clone']}}</td>
        <td v-if="item.notes" style="max-width: 360px;"><div class="divnotes" @click="noteitem={...item,...{idx:index}};showNotes();" v-html="item.notes.replace('/\n/g','<br>')"></div></td>
        <td v-if="!item.notes"><a v-if="item.id" @click="noteitem={...item};showNotes();">✍</a></td>
        <td><span style="color: #bbb;">{{item['student.name']}}</span><br>
          {{item['student.block']==4?'⛔':item['student.block']==3?'⭕':item['student.block']==2?'🔒🔒':item['student.block']==1?'🔒':''}}
          {{item['student.alert']>2?('❗'+arrNums[item['student.alert']]+'❗'):item['student.alert']==2?'❗❗':item['student.alert']==1?'❗':item['student.alert']==(-1)?'🌵':''}}
          {{item['student.loginname']}}
          {{Math.floor(item['student.pall']/100%10)>0?' ❰pc'+Math.floor(item['student.pall']/100%10)+'❱':''}}
          {{Math.floor(item['student.pall']/1000%10)>0?' ❰oc'+Math.floor(item['student.pall']/1000%10)+'❱':''}}
          {{Math.floor(item['student.pall']/10000%10)>0?' ❰ps'+Math.floor(item['student.pall']/10000%10)+'❱':''}}
          {{Math.floor(item['student.pall']/100000%10)>0?' ❰ss'+Math.floor(item['student.pall']/100000%10)+'❱':''}}
          {{Math.floor(item['student.pall']%100)>0?' ❰rt'+Math.floor(item['student.pall']%100)+'❱':''}}
        </td>
        <td>{{item['student.gender']}}</td>
        <td>{{item['student.grade']}}
          <span v-if="item['student.doidt']==2">⛺</span>
        </td>
        <td>
          {{new Date(item['student.tested_at']).toLocaleDateString().replace(/01\/01\/1980/,' --')}}
          {{new Date(item['student.viewed_at']).toLocaleDateString().replace(/01\/01\/1980/,' --')}}
        </td>
        <td>{{item['student.score']}}</td>
        <td>{{item['student.avg5']}}</td>        
        <td>{{item.mobile}}</td>        
        <td>{{item.address}}</td>
        <td>{{item.suburb}}<br>{{item.postcode}}</td>
        <td>{{item.credit}}</td>
        <td>{{item.buy_at?new Date(item.buy_at).toLocaleDateString().replace(/01\/01\/1980/,' --'):''}}</td>
        <td>{{item.visit_at?new Date(item.visit_at).toLocaleDateString():''}}</td>
      </tr>    
    </table>
    <div class="pageline">
      <div @click="onChange(item)" v-for="(item) in arrPageNum" :key="'pg'+item" 
       :class="(currentpage==item)?'pagetab bk_selpg':'pagetab'">{{item}}
      </div>
    </div>
    <span style="color:#999;">@PC499级别4年级分数00按套数倒序；@EN设置英文语言的；@IN查关闭idt的；@RN查没看过rpt的；@RV30最近30天内访问过，小数*1000代表分钟</span>
  </div>
</template>

<script>
import { Geter,Puter } from '@/utils/request.js';
import swal from 'sweetalert2';

export default {
  name:'testlist',
  data () {
    return {
      users: [],
      selectname: '',
      lockname: false,
      totalpages: 1,
      currentpage: 1,
      totalitems: 0,
      arrPageNum: [],
      iptsearch: '',
      valuechk: 's',
      shownotes: false,
      noteitem: {},
      arrNums: ['','①', '②', '③', '④', '⑤', '⑥', '⑦', '⑧', '⑨', '⑩', '⑪', '⑫', '⑬', '⑭', '⑮'],
      location: {}
    };
  },

  components: {},

  computed: {},

  beforeMount() {},

  mounted() {
    this.getUserList();
  },

  methods: {
    async getUserList(topg=0,chk='',sch='') {
      try {
        let response = await Geter(`userlist`,{pg:topg,chk:chk,sch:sch});
        if (Object.keys(response.data).length && response.data!='Access Denied'){
          this.users = response.data.rows;
          console.log(this.users);
          this.totalitems = response.data.count;
          this.totalpages = Math.ceil(response.data.count/100);
          this.arrPageNum = [];
          this.currentpage = 1 + parseInt(response.data.pg);
          for(let i=1;i<=this.totalpages;i++){
            if(Math.abs(this.currentpage-i)<6){
              this.arrPageNum.push(i);
            }else if(i%10==0){
              this.arrPageNum.push(i);
            }else if(i>this.totalpages){
              this.arrPageNum.push(i);
            }else if(i<10){
              this.arrPageNum.push(i);
            }
          }
        }
      }catch(e) {
        console.log(e);
      }
    },

    search(num){
      if(num==0){
        this.valuechk = 's';
        this.iptsearch = '';
      }
      this.iptsearch = this.iptsearch.trim();
      this.getUserList(0,this.valuechk,this.iptsearch);
    },

    async mock(stu){
      console.log(stu);
      try {
        let response = await Geter(`mockstudent`,{st:stu});
        if (response.data==201){
          swal.fire({text: 'Successfully imitating the student!',
          timer: 3000,
          showConfirmButton: false});
        }
      }catch(e) {
        console.log(e);
      }
    },

    onChange(topg) {
      this.getUserList(topg,this.valuechk,this.iptsearch);
    },

    async showNotes(){
      this.location = {}
      this.shownotes = true;
      let ipAddress = this.noteitem.visit_ip.split(',')[0];
      this.noteitem.ip = ipAddress.substring(0,20);
      let res = await Geter(`https://ipapi.co/${ipAddress}/json/`)
      this.location = res.data;
    },

    async upNotes(type){
      if(type){
        try {
          let response = await Puter('upnote',{id:this.noteitem.id,notes:this.noteitem.notes.trim()});
          if(response && response.data==201){
            this.shownotes = false;
            this.noteitem = {};
            this.getUserList(this.currentpage,this.valuechk,this.iptsearch);
          }else{
            swal.fire({text: 'Update failed! To clear please type "Del".',
            timer: 3000,
            showConfirmButton: false});
          }
        }catch(e) {
          console.log(e);
        }
      }else{
        this.shownotes = false;
        this.noteitem = {};
      }
    },

    chgNote(n) {
      if(n==1){
        this.noteitem.notes = '♣' + this.noteitem.notes;
      }else if(n==2){
        this.noteitem.notes = this.noteitem.notes + '【】';
      }
    },

    async chgLang(id,idx){
      try {
        let response = await Geter('chglang',{id:this.noteitem.id,notes:this.noteitem.notes.trim()});
        if(response && response.data==201){
          this.noteitem.lang = 3 - this.noteitem.lang;
          this.users[idx].lang = this.noteitem.lang;
          this.getUserList(this.currentpage,this.valuechk,this.iptsearch);
        }
      }catch(e) {
        console.log(e);
      }
    },

  },

}

</script>
<style scoped>
#usernotes{
  position: fixed;
  z-index: 99;
  left: 10%;
  top: 30%;
  width: 580px;
  height: 340px; 
  margin: 8px 8px;
  padding: 16px;
  overflow: none; 
  border-width: 1px;
  border-style: solid; 
  border-color: lightgray;   
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.258824),0 2px 6px 0 rgba(0,0,0,0.156863);
  background-color: white;
  text-align: left;
}
.divnotes:hover{
  cursor:pointer;
}

.userlist{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 20px;
}
.pageline{
  margin: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-flow: wrap;
}
.pagetab{
  margin: 0 4px;
  width: 20px;
  height: 20px;
  text-align: center;
  border: 1px solid #999;  
  border-radius: 3px;  
}
.pagetab:hover{
  cursor:pointer;
  opacity: 0.85;
  box-shadow: 1px 1px 0 1px #666,
              1px 1px 2px 1px #999;
}
.bk_selpg{
  background-color: #FFD54F;
}


.tb88  {
  width: 95%;
  margin: 24px;
  word-break: break-all;
  white-space: pre-line;
}
.tb88 tr td{
  border: 1px solid black;
}
.cl_doing{
  color: rgb(255, 0, 0);
}
.cl_done{
  color: #333;
}
.td_name:hover{
  cursor:pointer;
}

.act_botton {
  font-size: 13px;
  line-height: 18px;
  border-width: 1px;
  border-style: solid;    
  border-color: #666;   
  border-radius: 16px;
  padding: 2px 8px 2px 8px;
  margin: 0 4px 0 4px;
  color: white;
  text-shadow: 0 0 1px #000;
  background: #7d9dda;
}
.act_botton:hover{
  cursor:pointer;
  opacity: 0.85;
  box-shadow:1px 1px 2px #666;
}

@media screen and (max-width: 1000px) {
  *,textarea,button,span {
    font-size: 12px;
  }
  #usernotes{
    left: 1%;
    top: 25%;
    font-size: 10px;
    width: 400px;
    height: 300px; 
    padding: 8px;
  }
}
</style>