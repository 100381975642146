<template>
  <div id="canvCont">
    <canvas :ref="'canva'+transdata.canum" width="500" height="400" ></canvas>
  </div>
</template>

<script>
import { fabric } from 'fabric';

export default {
  name: "canva",

  data(){
    return{
      mycanvas: {},
      arrGroupObjs: [],
      intGrpObjInx: 1,
      intGroupSta: 0,
      intGroupEnd: 0,
      arrGrpGroups: [],
      intGrpGrpInx: 1,
      intGrpGrpSta: 0,
      intGrpGrpEnd: 0,
      arrTessNS: [],
    }
  },

  props: {                
    transdata: {},
  },

  watch: {
    transdata: {
      handler () {
        this.mycanvas.clear();
        this.arrGroupObjs = [];
        this.intGrpObjInx = 1;
        this.intGrpGrpSta = 0;
        this.arrGrpGroups = [];
        this.intGrpGrpInx = 1;
        this.intGrpGrpSta = 0;
        this.arrTessNS = [],
        this.splitLine();
      },
      deep: true
    }
  },

  mounted(){
    let ref = this.$refs['canva'+this.transdata.canum];
    this.mycanvas = new fabric.StaticCanvas(ref);
    this.splitLine();
  },

  methods: {
    splitLine(){
      if(!this.transdata.cacon){
        return;
      }
      let lines = this.transdata.cacon.split(/\n/);
      lines.forEach((val)=>{ 
        if(/^\d+,\d+#\w*$/.test(val)){ 
          val.replace(/^(\d+),(\d+)#(\w*)$/,(match,p1,p2,p3)=>{
            this.mycanvas.setWidth(parseInt(p1));
            this.mycanvas.setHeight(parseInt(p2));
            if(!p3 || p3!='NOB'){
              this.drawBack(parseInt(p1),parseInt(p2));
            }
            return '';
          })        
        }
        if(/^GRST#/.test(val)){ 
          this.intGroupSta = this.intGrpObjInx;
        }
        if(/^GREN#/.test(val)){ 
          this.intGroupEnd = this.intGrpObjInx;
          this.actGroup(val);
          this.intGroupSta = 0;
        }
        if(/^TEST#/.test(val)){ 
          this.intGroupSta = this.intGrpObjInx;
          val.replace(/^TEST#NS(.*)/,(match,nsval)=>{
            nsval.replace(/(\d+),(\d+),(\d+),(\d+);/g,(match,p1,p2,p3,p4)=>{
              this.arrTessNS.push({
                x1:p1,
                y1:p2,
                x2:p3,
                y2:p4,
              })
              return;
            })  
            return;
          })       
        }
        if(/^TEEN#/.test(val)){ 
          this.intGroupEnd = this.intGrpObjInx;
          this.actTess(val);
          this.intGroupSta = 0;
        }
        if(/^IMAG\(/.test(val)){ 
          this.drawImag(val);
        }
        if(/^RECT\(/.test(val)){ 
          this.drawRect(val);
        }
        if(/^TRIA\(/.test(val)){ 
          this.drawTria(val);
        }
        if(/^LINE\(/.test(val)){ 
          this.drawLine(val);
        }
        if(/^TEXT\(/.test(val)){ 
          this.drawText(val);
        }
        if(/^CIRC\(/.test(val)){ 
          this.drawCirc(val);
        }
        if(/^PATH\(/.test(val)){ 
          this.drawPath(val);
        }
        if(/^GR2S#/.test(val)){ 
          this.intGrpGrpSta = this.intGrpGrpInx;
        }
        if(/^GR2E#/.test(val)){ 
          this.intGrpGrpEnd = this.intGrpGrpInx;
          this.grpGroup(val);
          this.intGrpGrpSta = 0;
        }
      });
      
    },

    drawBack(w,h){
      let objBack = new fabric.Rect({
        left: 0,
        top: 0,
        width: w,
        height: h,
        fill: '#fff',
      });
      this.mycanvas.insertAt(objBack,0);
    },

    splitParam(strParam){
      let splitData = {};
      strParam.replace(/^(?:FI([#\w\d]+);)?(?:ST([#\w\d]+);)?(?:SW(\d{1,3});)?(?:OP(\d\.?\d*);)?(?:AN(-?\d{1,3});)?(?:AS(-?\d{1,3});)?(?:AE(-?\d{1,3});)?(?:SX(\d\.?\d*);)?(?:SY(\d\.?\d*);)?(?:FX([01]);)?(?:FY([01]);)?(?:KX(-?\d+\.?\d*);)?(?:KY(-?\d+\.?\d*);)?(?:OX(\w+);)?(?:OY(\w+);)?(?:RX(\d+);)?(?:RY(\d+);)?(?:FT([ \w]+);)?(?:FS(\d+);)?(?:FW(normal|bold);)?(?:CP(\d+);)?(?:DA(\d+);)?$/,(match,p1,p2,p3,p4,p5,p6,p7,p8,p9,p10,p11,p12,p13,pox,poy,p14,p15,p16,p17,p18,p19,p20)=>{
        if(p1){
          if(p1=='#0'){p1='#000'}
          if(p1=='#1'){p1='#fff'}
          if(p1=='#2'){p1='#f43'}
          if(p1=='#3'){p1='#28e'}
          if(p1=='#4'){p1='#4a5'}
          if(p1=='#5'){p1='#fd3'}
          if(p1=='#9'){p1='#999'}
          splitData.fill = p1 
        }
        if(p2){
          if(p2=='#0'){p2='#000'}
          if(p2=='#1'){p2='#fff'}
          if(p2=='#2'){p2='#f43'}
          if(p2=='#3'){p2='#28e'}
          if(p2=='#4'){p2='#4a5'}
          if(p2=='#5'){p2='#fd3'}
          if(p2=='#9'){p2='#999'}
          splitData.stroke = p2 
        }
        if(p3){splitData.strokeWidth = parseInt(p3) }
        if(p4){splitData.opacity = parseFloat(p4) }
        if(p5){splitData.angle = parseInt(p5) }
        if(p6){splitData.startAngle = parseInt(p6) }
        if(p7){splitData.endAngle = parseInt(p7) }
        if(p8){splitData.scaleX = parseFloat(p8) }
        if(p9){splitData.scaleY = parseFloat(p9) }
        if(p10==1){splitData.flipX = true }
        if(p11==1){splitData.flipY = true }
        if(p12){splitData.skewX = parseFloat(p12) }
        if(p13){splitData.skewY = parseFloat(p13) }
        if(pox){splitData.originX = pox=='c' ? 'center' : pox }
        if(poy){splitData.originY = poy=='c' ? 'center' : poy }
        if(p14){splitData.rx = parseInt(p14) }
        if(p15){splitData.ry = parseInt(p15) }
        if(p16){splitData.fontFamily = p16 }
        if(p17){splitData.fontSize = parseInt(p17) }
        if(p18){splitData.fontWeight = p18 }
        if(p19){splitData.charSpacing = parseInt(p19) }
        if(p20){splitData.strokeDashArray = [parseInt(p20),parseInt(p20)/2] }
        return '';
      }); 
      return splitData;
    },

    drawLine(val){
      let arrCoords = [];
      let objParam = {stroke:'#000'};
      let strCopy = '';
      let strParam = '';
      val.replace(/^LINE\((-?\d+),(-?\d+);(-?\d+),(-?\d+)\)((?:[A-Z]{2}[-.\d#\w]+;)*)?(\|.*)?$/,(match,p1,p2,p3,p4,p5,p6)=>{
        arrCoords = [parseInt(p1),parseInt(p2),parseInt(p3),parseInt(p4)];
        strParam = p5;
        strCopy = p6;
        return '';
      }); 
      if(/(?:[A-Z]{2}[-.\d#\w]+;)+/.test(strParam)){
        Object.assign(objParam, this.splitParam(strParam))
      }   
            
      this.arrGroupObjs[this.intGrpObjInx] = new fabric.Line(arrCoords,objParam);
      if(this.intGroupSta<1){
        this.mycanvas.add(this.arrGroupObjs[this.intGrpObjInx]);
      }
      this.intGrpObjInx++;

      if(/^\|/.test(strCopy)){ 
        let arrCopys = strCopy.replace(/^\|/,'').split('|');
        arrCopys.forEach((tCopy)=>{ 
          tCopy.replace(/(-?\d+),(-?\d+),(-?\d+)(?:\*(-?\d{1,2}))?/,(match,p1,p2,p3,p4)=>{
            let intCopys = 0;
            if(p4){
              let t4 = parseInt(p4);
              if(t4 < 0) intCopys = 0 - t4 - 1;
              else intCopys = t4;
            }else{
              intCopys = 1;
            }
            for(let i=0;i < intCopys;i++){
              arrCoords[0] +=  parseInt(p1);
              arrCoords[1] +=  parseInt(p2);
              arrCoords[2] +=  parseInt(p1);
              arrCoords[3] +=  parseInt(p2);
              objParam.angle += parseInt(p3);
              this.arrGroupObjs[this.intGrpObjInx] = new fabric.Line(arrCoords,objParam);
              if(this.intGroupSta<1){
                this.mycanvas.add(this.arrGroupObjs[this.intGrpObjInx]);
              }
              this.intGrpObjInx++;
            }
            return '';
          });
        })
      }
    },

    drawPath(val){
      let objParam = {stroke:'#000',fill:'transparent'};
      let strPath = '';
      let strCopy = '';
      let strParam = '';
      val.replace(/^PATH\((-?\d+),(-?\d+);([-., \d\w]+)\)((?:[A-Z]{2}[-.\d#\w]+;)*)?(\|.*)?$/,(match,p1,p2,path,p5,p6)=>{
        objParam.left = parseInt(p1);
        objParam.top = parseInt(p2);
        strPath = path;
        strParam = p5;
        strCopy = p6;
        return '';
      }); 
      if(/(?:[A-Z]{2}[-.\d#\w]+;)+/.test(strParam)){
        Object.assign(objParam, this.splitParam(strParam))
      }   

      this.arrGroupObjs[this.intGrpObjInx] = new fabric.Path(strPath,objParam);
      if(this.intGroupSta<1){
        this.mycanvas.add(this.arrGroupObjs[this.intGrpObjInx]);
      }
      this.intGrpObjInx++;

      if(/^\|/.test(strCopy)){ 
        let arrCopys = strCopy.replace(/^\|/,'').split('|');
        arrCopys.forEach((tCopy)=>{ 
          tCopy.replace(/(-?\d+),(-?\d+),(-?\d+)(?:\*(-?\d{1,2}))?/,(match,p1,p2,p3,p4)=>{
            let intCopys = 0;
            if(p4){
              let t4 = parseInt(p4);
              if(t4 < 0) intCopys = 0 - t4 - 1;
              else intCopys = t4;
            }else{
              intCopys = 1;
            }
            for(let i=0;i < intCopys;i++){
              objParam.left +=  parseInt(p1);
              objParam.top += parseInt(p2);
              objParam.angle += parseInt(p3);
              this.arrGroupObjs[this.intGrpObjInx] = new fabric.Path(strPath,objParam);
              if(this.intGroupSta<1){
                this.mycanvas.add(this.arrGroupObjs[this.intGrpObjInx]);
              }
              this.intGrpObjInx++;
            }
            return '';
          });
        })
      }
    },

    drawRect(val){
      let objParam = {stroke:'#000',fill:'transparent'};
      let strCopy = '';
      let strParam = '';
      val.replace(/^RECT\((-?\d+),(-?\d+);(\d+),(\d+)\)((?:[A-Z]{2}[-.\d#\w]+;)*)?(\|.*)?$/,(match,p1,p2,p3,p4,p5,p6)=>{
        objParam.left = parseInt(p1);
        objParam.top = parseInt(p2);
        objParam.width = parseInt(p3);
        objParam.height = parseInt(p4);
        strParam = p5;
        strCopy = p6;
        return '';
      }); 
      if(/(?:[A-Z]{2}[-.\d#\w]+;)+/.test(strParam)){
        Object.assign(objParam, this.splitParam(strParam))
      }   
      this.arrGroupObjs[this.intGrpObjInx] = new fabric.Rect(objParam);
      if(this.intGroupSta<1){
        this.mycanvas.add(this.arrGroupObjs[this.intGrpObjInx]);
      }
      this.intGrpObjInx++;


      if(/^\|/.test(strCopy)){ 
        let arrCopys = strCopy.replace(/^\|/,'').split('|');
        arrCopys.forEach((tCopy)=>{ 
          tCopy.replace(/(-?\d+),(-?\d+),(-?\d+)(?:\*(-?\d{1,2}))?/,(match,p1,p2,p3,p4)=>{
            let intCopys = 0;
            if(p4){
              let t4 = parseInt(p4);
              if(t4 < 0) intCopys = 0 - t4 - 1;
              else intCopys = t4;
            }else{
              intCopys = 1;
            }
            for(let i=0;i < intCopys;i++){
              objParam.left +=  parseInt(p1);
              objParam.top += parseInt(p2);
              objParam.angle += parseInt(p3);
              this.arrGroupObjs[this.intGrpObjInx] = new fabric.Rect(objParam);
              if(this.intGroupSta<1){
                this.mycanvas.add(this.arrGroupObjs[this.intGrpObjInx]);
              }
              this.intGrpObjInx++;
            }
            return '';
          });
        })
      }
    },

    drawTria(val){
      let objParam = {stroke:'#000',fill:'transparent'};
      let strCopy = '';
      let strParam = '';
      val.replace(/^TRIA\((-?\d+),(-?\d+);(\d+),(\d+)\)((?:[A-Z]{2}[-.\d#\w]+;)*)?(\|.*)?$/,(match,p1,p2,p3,p4,p5,p6)=>{
        objParam.left = parseInt(p1);
        objParam.top = parseInt(p2);
        objParam.width = parseInt(p3);
        objParam.height = parseInt(p4);
        strParam = p5;
        strCopy = p6;
        return '';
      }); 
      if(/(?:[A-Z]{2}[-.\d#\w]+;)+/.test(strParam)){
        Object.assign(objParam, this.splitParam(strParam))
      }   
      this.arrGroupObjs[this.intGrpObjInx] = new fabric.Triangle(objParam);
      if(this.intGroupSta<1){
        this.mycanvas.add(this.arrGroupObjs[this.intGrpObjInx]);
      }
      this.intGrpObjInx++;


      if(/^\|/.test(strCopy)){ 
        let arrCopys = strCopy.replace(/^\|/,'').split('|');
        arrCopys.forEach((tCopy)=>{ 
          tCopy.replace(/(-?\d+),(-?\d+),(-?\d+)(?:\*(-?\d{1,2}))?/,(match,p1,p2,p3,p4)=>{
            let intCopys = 0;
            if(p4){
              let t4 = parseInt(p4);
              if(t4 < 0) intCopys = 0 - t4 - 1;
              else intCopys = t4;
            }else{
              intCopys = 1;
            }
            for(let i=0;i < intCopys;i++){
              objParam.left +=  parseInt(p1);
              objParam.top += parseInt(p2);
              objParam.angle += parseInt(p3);
              this.arrGroupObjs[this.intGrpObjInx] = new fabric.Triangle(objParam);
              if(this.intGroupSta<1){
                this.mycanvas.add(this.arrGroupObjs[this.intGrpObjInx]);
              }
              this.intGrpObjInx++;
            }
            return '';
          });
        })
      }
    },

    drawText(val){
      let objParam = {};
      let strCopy = '';
      let strParam = '';
      let strText = '';
      val.replace(/^TEXT\((-?\d+),(-?\d+);([^;]+)(?:;([\d]+))?\)((?:[A-Z]{2}[-.\d#\w]+;)*)?(\|.*)?$/,(match,p1,p2,p3,num,p4,p5)=>{
        objParam.left = parseInt(p1);
        objParam.top = parseInt(p2);
        strText = p3.replace('#sp#','');
        if(parseInt(num)>0){
          let arrText = strText.split(',');
          strText = arrText[parseInt(num)-1];
        }
        strParam = p4;
        strCopy = p5;
        return '';
      }); 
      if(/(?:[A-Z]{2}[-.\d#\w]+;)+/.test(strParam)){
        Object.assign(objParam, this.splitParam(strParam))
      }   
            
      this.arrGroupObjs[this.intGrpObjInx] = new fabric.Text(strText,objParam);
      if(this.intGroupSta<1){
        this.mycanvas.add(this.arrGroupObjs[this.intGrpObjInx]);
      }
      this.intGrpObjInx++;

      if(/^\|/.test(strCopy)){ 
        let arrCopys = strCopy.replace(/^\|/,'').split('|');
        arrCopys.forEach((tCopy)=>{ 
          tCopy.replace(/(-?\d+),(-?\d+),(-?\d+)(?:\*(-?\d{1,2}))?/,(match,p1,p2,p3,p4)=>{
            let intCopys = 0;
            if(p4){
              let t4 = parseInt(p4);
              if(t4 < 0) intCopys = 0 - t4 - 1;
              else intCopys = t4;
            }else{
              intCopys = 1;
            }
            for(let i=0;i < intCopys;i++){
              objParam.left += parseInt(p1);
              objParam.top += parseInt(p2);
              objParam.angle += parseInt(p3);
              this.arrGroupObjs[this.intGrpObjInx] = new fabric.Text(strText,objParam);
              if(this.intGroupSta<1){
                this.mycanvas.add(this.arrGroupObjs[this.intGrpObjInx]);
              }
              this.intGrpObjInx++;
            }
            return '';
          });
        })
      }
    },

    drawCirc(val){
      let objParam = {stroke:'#000',fill:'transparent'};
      let strCopy = '';
      let strParam = '';
      val.replace(/^CIRC\((-?\d+),(-?\d+);(\d+)\)((?:[A-Z]{2}[-.\d#\w]+;)*)?(\|.*)?$/,(match,p1,p2,p3,p4,p5)=>{
        objParam.left = parseInt(p1);
        objParam.top = parseInt(p2);
        objParam.radius = parseInt(p3);
        strParam = p4;
        strCopy = p5;
        return '';
      }); 
      if(/(?:[A-Z]{2}[-.\d#\w]+;)+/.test(strParam)){
        Object.assign(objParam, this.splitParam(strParam))
      }   
      this.arrGroupObjs[this.intGrpObjInx] = new fabric.Circle(objParam);
      if(this.intGroupSta<1){
        this.mycanvas.add(this.arrGroupObjs[this.intGrpObjInx]);
      }
      this.intGrpObjInx++;

      if(/^\|/.test(strCopy)){ 
        let arrCopys = strCopy.replace(/^\|/,'').split('|');
        arrCopys.forEach((tCopy)=>{ 
          tCopy.replace(/(-?\d+),(-?\d+),(-?\d+)(?:\*(-?\d{1,2}))?/,(match,p1,p2,p3,p4)=>{
            let intCopys = 0;
            if(p4){
              let t4 = parseInt(p4);
              if(t4 < 0) intCopys = 0 - t4 - 1;
              else intCopys = t4;
            }else{
              intCopys = 1;
            }
            for(let i=0;i < intCopys;i++){
              objParam.left +=  parseInt(p1);
              objParam.top += parseInt(p2);
              objParam.angle += parseInt(p3);
              this.arrGroupObjs[this.intGrpObjInx] = new fabric.Circle(objParam);
              if(this.intGroupSta<1){
                this.mycanvas.add(this.arrGroupObjs[this.intGrpObjInx]);
              }
              this.intGrpObjInx++;
            }
            return '';
          });
        })
      }
    },

    drawImag(val){
      let strCopy = '';
      let imgSrc = '';
      let imgScale = 1;
      let imgIndex = 1;
      let objParam = {angle:0};
      let objImage = {};
      val.replace(/^IMAG\((-?\d+),(-?\d+);([^;]+)(?:;(\d+\.?\d*))?(?:;(\d+))?\)(\|.*)?$/,(match,left,top,src,scale,zindex,p5)=>{
        imgSrc= src;
        objParam.left = parseInt(left);
        objParam.top = parseInt(top);
        objParam.originX = 'center';
        objParam.originY = 'center';
        if(scale){imgScale = parseFloat(scale)}
        if(zindex){imgIndex = parseInt(zindex)}
        strCopy = p5;
        return '';
      }); 
      fabric.Image.fromURL(imgSrc, (img)=>{
        img.set(objParam)
        img.scale(imgScale);
        objImage = img;
        this.mycanvas.insertAt(objImage,imgIndex);

        if(/^\|/.test(strCopy)){ 
          let arrCopys = strCopy.replace(/^\|/,'').split('|');
          arrCopys.forEach((tCopy)=>{ 
            tCopy.replace(/(-?\d+),(-?\d+),(-?\d+)(?:\*(-?\d{1,2}))?/,(match,p1,p2,p3,p4)=>{
              let intCopys = 0;
              if(p4){
                let t4 = parseInt(p4);
                if(t4 < 0) intCopys = 0 - t4 - 1;
                else intCopys = t4;
              }else{
                intCopys = 1;
              }
              for(let i=0;i < intCopys;i++){
                objImage.clone((cloned)=> {
                  objParam.left += parseInt(p1);
                  objParam.top += parseInt(p2);                
                  objParam.angle += parseInt(p3);
                  cloned.set(objParam);
                  this.mycanvas.insertAt(cloned,imgIndex);
                });
              }
              return '';
            });
          })
        }     
      });


    },

    actGroup(val){
      let strCopy = '';
      let strParam = '';
      let intLeft = 0;
      let intTop = 0;
      let absMove = false;
      let objParam = {};
      let arrThisGroup = this.arrGroupObjs.splice(this.intGroupSta,this.intGroupEnd);

      val.replace(/^GREN#(?:\((\*?-?\d+),(-?\d+)\))?((?:[A-Z]{2}[-.\d#\w]+;)*)?(\|.*)?$/,(match,left,top,p1,p2)=>{  // 分解变化和复制数据
        strParam = p1;
        strCopy = p2;
        if(left){
          if(left.slice(0,1)=='*') {
            absMove = true;
            left = left.slice(1);
          }
          intLeft = parseInt(left)
        }
        if(top){intTop = parseInt(top)}
        return '';
      }); 
      if(/(?:[A-Z]{2}[-.\d#\w]+;)+/.test(strParam)){
        objParam = this.splitParam(strParam);
      }
      this.arrGrpGroups[this.intGrpGrpInx] = new fabric.Group(arrThisGroup, objParam);
      let thisGropOriginInx = this.intGrpGrpInx;
      if(absMove){
        objParam.left = intLeft;
        objParam.top = intTop;
      }else{
        objParam.left = this.arrGrpGroups[thisGropOriginInx].left + intLeft;
        objParam.top = this.arrGrpGroups[thisGropOriginInx].top + intTop;
      }
      this.arrGrpGroups[this.intGrpGrpInx].set(objParam);
      if(this.intGrpGrpSta<1){
        this.mycanvas.add(this.arrGrpGroups[this.intGrpGrpInx]);
      }      
      this.intGrpGrpInx++;

      if(/^\|/.test(strCopy)){ 
        let arrCopys = strCopy.replace(/^\|/,'').split('|');
        arrCopys.forEach((tCopy)=>{ 
          tCopy.replace(/(-?\d+),(-?\d+),(-?\d+)(?:\*(-?\d{1,2}))?/,(match,p1,p2,p3,p4)=>{
            let intCopys = 0;
            if(p4){
              let t4 = parseInt(p4);
              if(t4 < 0) intCopys = 0 - t4 - 1;
              else intCopys = t4;
            }else{
              intCopys = 1;
            }
            for(let i=0;i < intCopys;i++){
              this.arrGrpGroups[thisGropOriginInx].clone((cloned)=> {
                objParam.left += parseInt(p1);
                objParam.top += parseInt(p2);                
                objParam.angle += parseInt(p3);                
                cloned.set(objParam);
                this.arrGrpGroups[this.intGrpGrpInx] = cloned;
                if(this.intGrpGrpSta<1){
                  this.mycanvas.add(cloned);
                }
                this.intGrpGrpInx++;
              });
            }
            return '';
          });
        })
      }
    },

    grpGroup(val){
      let strCopy = '';
      let intLeft = 0;
      let intTop = 0;
      let absMove = false;
      let strParam = '';
      let objParam = {};
      let arrThisGroup = this.arrGrpGroups.splice(this.intGrpGrpSta,this.intGrpGrpEnd);

      val.replace(/^GR2E#(?:\((\*?-?\d+),(-?\d+)\))?((?:[A-Z]{2}[-.\d#\w]+;)*)?(\|.*)?$/,(match,left,top,p1,p2)=>{
        strParam = p1;
        strCopy = p2;
        if(left){
          if(left.slice(0,1)=='*') {
            absMove = true;
            left = left.slice(1);
          }
          intLeft = parseInt(left)
        }
        if(top){intTop = parseInt(top)}
        return '';
      }); 
      if(/(?:[A-Z]{2}[-.\d#\w]+;)+/.test(strParam)){
        objParam = this.splitParam(strParam);
      }   
      let newGrpGrpObj = new fabric.Group(arrThisGroup, objParam);
      if(absMove){
        objParam.left = intLeft;
        objParam.top = intTop;
      }else{
        objParam.left = newGrpGrpObj.left + intLeft;
        objParam.top = newGrpGrpObj.top + intTop;
      }
      newGrpGrpObj.set(objParam);
      this.mycanvas.add(newGrpGrpObj);

      if(/^\|/.test(strCopy)){ 
        let arrCopys = strCopy.replace(/^\|/,'').split('|');
        arrCopys.forEach((tCopy)=>{ 
          tCopy.replace(/(-?\d+),(-?\d+),(-?\d+)(?:\*(-?\d{1,2}))?/,(match,p1,p2,p3,p4)=>{
            let intCopys = 0;
            if(p4){
              let t4 = parseInt(p4);
              if(t4 < 0) intCopys = 0 - t4 - 1;
              else intCopys = t4;
            }else{
              intCopys = 1;
            }
            for(let i=0;i < intCopys;i++){
              newGrpGrpObj.clone((cloned)=> {
                objParam.left += parseInt(p1);
                objParam.top += parseInt(p2);                
                objParam.angle += parseInt(p3);                
                cloned.set(objParam);
                this.mycanvas.add(cloned);
              });
            }
            return '';
          });
        })
      }
    },

    actTess(val){
      let strCopy = '';
      let strParam = '';
      let intLeft = 0;
      let intTop = 0;
      let objParam = {};
      let arrThisGroup = this.arrGroupObjs.splice(this.intGroupSta,this.intGroupEnd);

      val.replace(/^TEEN#(?:\((-?\d+),(-?\d+)\))?((?:[A-Z]{2}[-.\d#\w]+;)*)?(\|.*)?$/,(match,left,top,p1,p2)=>{  // 分解变化和复制数据
        strParam = p1;
        strCopy = p2;
        if(left){intLeft = parseInt(left)}
        if(top){intTop = parseInt(top)}
        return '';
      }); 
      if(/(?:[A-Z]{2}[-.\d#\w]+;)+/.test(strParam)){
        objParam = this.splitParam(strParam);
      }
      let tessDrawing = new fabric.Group(arrThisGroup, objParam);
      objParam.left = tessDrawing.left + intLeft;
      objParam.top = tessDrawing.top + intTop;
      objParam.angle = tessDrawing.angle;
      objParam.flipX = tessDrawing.flipX;
      objParam.flipY = tessDrawing.flipY;
      tessDrawing.set(objParam);
      //this.mycanvas.add(tessDrawing);
      let arrTessSplit = [];

      if(/^\|/.test(strCopy)){ 
        val.replace(/\|(.*?)(?=\||$)/g,(match,p1)=>{  // 分解多个tess copy数据
          p1.replace(/(?:\((-?\d+),(-?\d+),(-?\d+),(\d),(\d)\))?(\d+)\*(\d+);(?:AN(-?\d+);)?(?:AO(-?\d+);)?(?:FX(-?\d+);)?(?:FY(-?\d+);)?(\d+)\*(\d+);(?:AN(-?\d+);)?(?:AO(-?\d+);)?(?:FX(-?\d+);)?(?:FY(-?\d+);)?/,(match,p1,p2,p3,p4,p5,tx1,tx2,txan,txao,txfx,txfy,ty1,ty2,tyan,tyao,tyfx,tyfy)=>{  // 
            let objCopyParam = JSON.parse(JSON.stringify(objParam));
            arrTessSplit.left = p1 ? parseFloat(p1) : 0;
            arrTessSplit.top = p2 ? parseFloat(p2) : 0;
            arrTessSplit.angle = p3 ? parseFloat(p3) : 0;
            arrTessSplit.flipX = p4==1 ? true : false;
            arrTessSplit.flipY = p5==1 ? true : false;
            objCopyParam.left = objCopyParam.left + arrTessSplit.left;
            objCopyParam.top = objCopyParam.top + arrTessSplit.top;
            //objCopyParam.angle = arrTessSplit.angle;
            objCopyParam.angle = objCopyParam.angle + arrTessSplit.angle;
            objCopyParam.flipX = arrTessSplit.flipX ? !objCopyParam.flipX : objCopyParam.flipX;
            objCopyParam.flipY = arrTessSplit.flipY ? !objCopyParam.flipY : objCopyParam.flipY;

            arrTessSplit.offsetX = tx1 ? parseInt(tx1) : 0;
            arrTessSplit.multiX = tx2 ? parseInt(tx2) : 1;
            arrTessSplit.anX = txan ? parseFloat(txan) : 0;
            arrTessSplit.aoX = txao ? parseFloat(txao) : 0;
            arrTessSplit.fxX = txfx ? parseInt(txfx) : 0;
            arrTessSplit.fyX = txfy ? parseInt(txfy) : 0;
            arrTessSplit.offsetY = ty1 ? parseInt(ty1) : 0;
            arrTessSplit.multiY = ty2 ? parseInt(ty2) : 1;
            arrTessSplit.anY = tyan ? parseFloat(tyan) : 0;
            arrTessSplit.aoY = tyao ? parseFloat(tyao) : 0;
            arrTessSplit.fxY = tyfx ? parseInt(tyfx) : 0;
            arrTessSplit.fyY = tyfy ? parseInt(tyfy) : 0;
            for(let i=0;i<=arrTessSplit.multiX;i++){
              for(let j=0;j<=arrTessSplit.multiY;j++){
                //if(i!=0 || j!=0){
                  let tessAN = arrTessSplit.anX * i + arrTessSplit.anY * j;
                  let tessAO = arrTessSplit.aoX * (i % 2) + arrTessSplit.aoY * (j % 2);
                  let tessFX = (arrTessSplit.fxX * (i % 2) + arrTessSplit.fxY * (j % 2)) % 2;
                  let tessFY = (arrTessSplit.fyX * (i % 2) + arrTessSplit.fyY * (j % 2)) % 2;
                  let tessParam = [];
                  tessParam.left = objCopyParam.left + arrTessSplit.offsetX * i;
                  tessParam.top = objCopyParam.top + arrTessSplit.offsetY * j;
                  tessParam.angle = objCopyParam.angle + (tessAN>0 ? tessAN : tessAO);
                  tessParam.flipX = tessFX>0 ? !objCopyParam.flipX : objCopyParam.flipX;
                  tessParam.flipY = tessFY>0 ? !objCopyParam.flipY : objCopyParam.flipY;

                  let checkNS = false;
                  this.arrTessNS.forEach((item)=>{
                    if(tessParam.left>item.x1 && tessParam.left<item.x2 && tessParam.top>item.y1 && tessParam.top<item.y2){
                      checkNS = true;
                    }
                  })
                  if(!checkNS){
                    tessDrawing.clone((cloned)=> {
                      cloned.set(tessParam);
                      this.mycanvas.add(cloned);
                    });              
                  }
                //}
              }
            }
            return;
          }); 
          return;
        });         
      }
    },

  },
}

</script>
<style scoped>
#canvCont {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>

