import { createApp } from 'vue';
import App from './App';

import Antd from 'ant-design-vue';
import store from './utils/store'

import './mystyle.less';

const app = createApp(App);

app.use(Antd);

app.use(store);

app.mount('#app');