import { createStore } from 'vuex';

export default createStore({
  state: {
    logined: false,
    loginuser: {},
    showdata: {},
    editdata: {},
    listskill: {},
    skillcasc: [],
    listchanged: false,
    list2pos: 1,
    sysparam: [],
    showpopsp: false,
  },
  getters: {
    getUser: state =>{
      if(state.loginuser){
        return state.loginuser
      }
    }
  },
  
  mutations: {
    setloginuser(state,data){
      if(data){
        state.logined = true;
        state.loginuser = data;
      }else{
        state.logined = false;
      }
    },
    offpopsp(state){
      state.showpopsp = !state.showpopsp; 
    },
    refreshlist(state, swch=1){
      state.listchanged = !state.listchanged;
      state.list2pos = swch;
    },
    setsysparam(state,data){
      state.sysparam = data;
    },
    setlistskill(state,data){
      state.listskill = data;
    },
    setskillcasc(state,data){
      state.skillcasc = data;
    },
    setshowdata(state,data){
      state.showdata = data;
    },
    seteditdata(state,data){
      state.editdata = data;
    }
  },
})
