<template>
<div>
  <a-affix :offset-top="4">
    <div id='shortcut'>
      <a-button type="Default" shape="circle" size="small" @click="putOtherSC('Cen')">
        <VerticalAlignMiddleOutlined :rotate="90" />
      </a-button>
      <a-button type="Default" shape="circle" size="small" @click="putOtherSC('Dv1')">
        <BorderOutlined />
      </a-button>
      <a-button type="Default" shape="circle" size="small" @click="putOtherSC('Qts')">
        <PaperClipOutlined />
      </a-button>
      <a-button type="Default" shape="circle" size="small" @click="putOtherSC('Tb1')">
        <BorderOuterOutlined />
      </a-button>
      <a-button type="Default" shape="circle" size="small" @click="putOtherSC('Tb9')">
        <BorderInnerOutlined />
      </a-button>
      <a-button type="Default" shape="circle" size="small" @click="putOtherSC('Pie')">
        <PieChartOutlined />
      </a-button>
      <a-button type="Default" shape="circle" size="small" @click="putOtherSC('Bar')">
        <BarChartOutlined />
      </a-button>
      <div v-if="myform.mainskill==171" class="sc_gap"> </div>
      <div v-if="myform.mainskill==171">
        <a-button v-for="(citem, index) in arrcloze" :key="'btnC'+index" type="Default" shape="circle" size="small" @click="putShortcut('█'+citem)">
          {{citem}}
        </a-button> 
      </div>

      <div v-if="myform.dsp_items" class="sc_gap"> </div>
      <div v-for="(dspitem, index) in myform.dsp_items" :key="'btnD'+index">
        <a-button type="Default" shape="circle" size="small" @click="putShortcut('D'+(index+1))">
          D{{ index+1 }}
        </a-button>
      </div>

      <div v-if="myform.act_items" class="sc_gap"> </div>
      <div v-for="(actitem, index) in myform.act_items" :key="'btnA'+index">
        <a-button type="dashed" shape="circle" size="small" @click="putShortcut('A'+(index+1))">
          A{{ index+1 }}
        </a-button>
      </div>

      <div v-if="myform.mainskill>=94 && myform.mainskill<=100" class="sc_gap"> </div>
      <div v-if="myform.mainskill>=94 && myform.mainskill<=100">Left
        <a-button v-for="(citem, index) in arrphoto" :key="'btnPLF'+index" type="Default" shape="circle" size="small" @click="putOtherSC('PLF',citem)">
          F{{citem}}
        </a-button> 
      </div>
      <div v-if="myform.mainskill>=94 && myform.mainskill<=100" class="sc_gap"> </div>
      <div v-if="myform.mainskill>=94 && myform.mainskill<=100">
        <a-button v-for="(citem, index) in arrphoto" :key="'btnPLM'+index" type="Default" shape="circle" size="small" @click="putOtherSC('PLM',citem)">
          M{{citem}}
        </a-button> 
      </div>    
      <div v-if="myform.mainskill>=94 && myform.mainskill<=100" class="sc_gap"> </div>
      <div v-if="myform.mainskill>=94 && myform.mainskill<=100">Right
        <a-button v-for="(citem, index) in arrphoto" :key="'btnPRF'+index" type="Default" shape="circle" size="small" @click="putOtherSC('PRF',citem)">
          F{{citem}}
        </a-button> 
      </div>    
      <div v-if="myform.mainskill>=94 && myform.mainskill<=100" class="sc_gap"> </div>
      <div v-if="myform.mainskill>=94 && myform.mainskill<=100">
        <a-button v-for="(citem, index) in arrphoto" :key="'btnPRM'+index" type="Default" shape="circle" size="small" @click="putOtherSC('PRM',citem)">
          M{{citem}}
        </a-button> 
      </div>    
      
      </div>
  </a-affix>

  <div :style="{backgroundColor: (myform.id > 0) ? '#B0BEC5' : '#ef9a9a'}" :v-if="!loadingData &&!loadingParam" id="edit-box">
    <a-form :wrapper-col="{span: 24}" layout="vertical">
      <a-row type="flex" justify="space-between" style="marginBottom: 8px">
        <a-col :span="6">
          <a-select v-model:value="myform.show_type" placeholder="Show type" @change="onChangeForm" >
            <a-select-option :value="1"> 
              Cap left & Q A right
            </a-select-option>
            <a-select-option :value="2">
              (C) Q left & A right
            </a-select-option>
            <a-select-option :value="4">
              Only One Block
            </a-select-option>
          </a-select>
        </a-col>
        <a-col :span="6">
          <a-select v-model:value="myform.type_id"  placeholder="Quest type" @change="onChangeForm" >
            <a-select-option v-for="(item) in arrTypes" :key="item.name+item.key" :value="item.key"> 
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col :span="3">
          <a-select v-model:value="myform.belong" @change="onChangeForm" >
            <a-select-option value="SS">
              SS
            </a-select-option>
            <a-select-option value="OC">
              OC
            </a-select-option>
          </a-select>
        </a-col>
        <a-col :span="5">
          <a-select v-model:value="myform.mainskill" @change="onChangeForm" >
            <a-select-option v-for="(item) in arrSkills" :key="item.name+item.key" :value="item.key" :disabled="item.slc==0 ? true : false"> 
              {{ item.name }}    
            </a-select-option>
          </a-select>
        </a-col>        
        <a-col :span="4" style="text-align:right;marginTop: 6px;" >
            CAP  <a-switch :checked="showCap"  @change="onoffshowCap" />
        </a-col>
      </a-row>

      <div v-show="showCap">
        <a-row>
          <a-col :span="24">
          <a-form-item style="marginBottom: 0px" :lable-col="{span: 24}" :wrapper-col="{span: 24}">
            Cap {{myform.cap_id}} content {{ myform.cap_content ? '(' + myform.cap_content.length + ' characters)':'' }}
            <a-textarea class="text_input" id="txtCap" v-model:value="myform.cap_content" placeholder="please enter the cap" :rows="10" @change="onChangeForm" @blur="handleBlur($event,'CAP')" />
          </a-form-item>
          </a-col>
        </a-row>
      </div>

      <a-row type="flex" justify="space-between"  style="marginBottom: 2px">
        <a-col :span="4" v-if="showCap">
          <a-button size="small" @click="chooseImg('IC')">Cap Image</a-button>
        </a-col>
        <a-col :span="6">
          <a-button size="small" @click="chooseImg('IQ')">Quest Image</a-button>
          <input type="file" ref="inputImg"
              style="display: none"
              @change="previewImg" accept="image/gif,image/jpeg,image/jpg,image/png" />                
        </a-col>
        <a-col :span="4" v-if="!showCap">
          
        </a-col>
        <a-col :span="2">
          
        </a-col>
        <a-col :span="6">
          <a-radio-group name="radioImgSize" v-model:value="selImgSize" size="small">
            <a-radio-button value="B">
              Big
            </a-radio-button>
            <a-radio-button value="M">
              Mid
            </a-radio-button>
            <a-radio-button value="S">
              Small
            </a-radio-button>
          </a-radio-group>
        </a-col>
        <a-col :span="6" style="text-align:right;">
          <a-radio-group name="radioImgPos" v-model:value="selImgPos" size="small">
            <a-radio-button value="L">
              Left
            </a-radio-button>
            <a-radio-button value="C">
              Center
            </a-radio-button>
            <a-radio-button value="R">
              Right
            </a-radio-button>
          </a-radio-group>
        </a-col>
      </a-row>
      <a-row type="flex" justify="start" align="bottom" class='imgRow'>
        <a-col :span="4" v-for="(item,index) in imgUrls" :key="'img'+item.key" class='imgCol'>
          <div class="imgBox">                     
            <img class="imgPic" :src="item.url" @click="putImgLink(item,selImgSize,selImgPos)">
            {{ item.typ }}{{ item.key }}   
            <a @click="delFbImg(item,index)">Del</a>
          </div>
        </a-col>
      </a-row>

      <a-row type="flex" justify="space-between">
        <a-col :span="12" style="marginTop: 8px">
          Question {{myform.id}} content {{ myform.quest_content ? '(' + myform.quest_content.length + ' Chr)':'' }}
        </a-col>
        <a-col :span="2" style="marginTop: 8px">
          Notes
        </a-col>
        <a-col :span="10">
          <a-form-item style="marginBottom: 4px">
            <a-input v-model:value="myform.notes" placeholder="Notes" @change="onChangeForm" />
          </a-form-item>
        </a-col>
      </a-row>  

      <a-form-item style="marginBottom: 0px">
        <a-textarea class="text_input" id="txtCnt" v-model:value="myform.quest_content" placeholder="please enter the content" :rows="10" @change="onChangeForm" @blur="handleBlur($event,'CNT')" />
      </a-form-item>

      <a-row type="flex" justify="space-between">
        <a-col :span="2">
          <a-form-item style="marginBottom: 4px">
            Level <a-input v-model:value="myform.level" placeholder="Level" @change="onChangeForm" />
          </a-form-item>
        </a-col>
        <a-col :span="2">
          <a-form-item style="marginBottom: 4px">
            Steps <a-input v-model:value="myform.steps" placeholder="Steps" @change="onChangeForm" />
          </a-form-item>
        </a-col>
        <a-col :span="5">
          <a-form-item style="marginBottom: 4px">
            OtherSkill
            <a-select v-model:value="myform.otherskill" placeholder="Other Skill" @change="onChangeForm" >
              <a-select-option v-for="(item) in arrSkills" :key="item.name+item.key" :value="item.key" :disabled="(item.slc==0 || item.key==myform.mainskill) ? true : false"> 
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
        <a-col :span="13">
          <a-form-item style="marginBottom: 4px">
            Tricks
            <a-select mode="multiple" v-model:value="myform.formtricks" placeholder="Tricks" @change="onChangeForm" >
              <a-select-option v-for="(item) in arrTricks" :key="item.name+item.key" :value="item.key.toString()"> 
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>  


      <a-row type="flex" justify="space-between" style="marginBottom: 4px">
        <a-col :span="10">
          DSP items 
        </a-col>
        <a-col :span="2">
          <a-input v-model:value="dspNumNew" size="small" />
        </a-col>
        <a-col :span="6" style="text-align:right;">
          <a-button type="dashed" size="small" @click="resetItems('dsp',dspNumNew)">Set DSP Nums</a-button> 
        </a-col>
        <a-col :span="6" style="text-align:right;">
          <a-button type="dashed" size="small" @click="resetItems('dsp')">Delete All DSP</a-button> 
        </a-col>
      </a-row>  

      <a-row :gutter="8" style="marginBottom: 8px">
        <a-col v-for="(dspitem, index) in myform.dsp_items" :key="dspitem.key" :span="12">
          <div :class="dspitem.cited==0?'dsp_div0': dspitem.cited==4?'dsp_div4':'dsp_div'">
            <a-input v-model:value="dspitem.content" :addonBefore="'D'+(index+1)" :placeholder="'DSP'+ (index+1)" @change="onChangeForm"  @blur="handleBlur($event,'D'+ (index+1))" />
          </div>
        </a-col>
      </a-row>

      <a-row type="flex" justify="space-between" style="marginBottom: 4px">
        <a-col :span="10">
          ACT items 
        </a-col>
        <a-col :span="2">
          <a-input v-model:value="actNumNew" size="small" />
        </a-col>
        <a-col :span="6" style="text-align:right;">
          <a-button  type="dashed" size="small" @click="resetItems('act',actNumNew)">Set ACT Nums</a-button> 
        </a-col>
        <a-col :span="6" style="text-align:right;">
          <a-button type="dashed" size="small" @click="resetItems('act')">Delete All ACT</a-button> 
        </a-col>
      </a-row>  

      <a-row :gutter="8" style="marginBottom: 4px">
        <a-col v-for="(actitem, index) in myform.act_items" :key="actitem.key" :span="12">
          <div style="{display:inline-block;marginBottom: 4px;}">
            <a-input :addonBefore="'A'+(index+1)" v-model:value="actitem.mate" :placeholder="'Mate'+ (index+1)" @change="onChangeForm" style="width: 40%;" />
            <a-input v-model:value="actitem.answer" :placeholder="'Answer'+ (index+1)" @change="onChangeForm" @blur="handleBlur($event,'N'+ (index+1))" style="width: 30%;" />
            <a-select v-model:value="actitem.trick" placeholder="Trick" :allowClear="true" @change="onChangeForm" style="width: 30%;">
              <a-select-option v-for="(item) in arrTricks" :key="item.name+item.key" :value="item.key"> 
                {{ item.name }}
              </a-select-option>
            </a-select>
          </div>
          <div class="text_input" style="marginBottom: 8px">
            <a-textarea v-model:value="actitem.content" :placeholder="'ACT'+ (index+1)" @change="onChangeForm" @blur="handleBlur($event,'A'+ (index+1))" :rows="2" />
          </div>
        </a-col>
      </a-row>
      <a-form-item style="marginBottom: 0px">
        Resolve {{ myform.resolve ? '(' + myform.resolve.length + ' characters)':'' }}
        <a-textarea class="text_input" v-model:value="myform.resolve" placeholder="please enter resolve" :rows="8" @change="onChangeForm"  @blur="handleBlur($event,'RSV')" />
      </a-form-item>
      <a-form-item style="marginBottom: 0px;marginTop: 0px;textAlign:left;marginLeft: 12px;">
        <a-popconfirm placement="top" ok-text="Yes" cancel-text="No" @confirm="delter()">
          <template v-slot:title>
            <p>Are you sure you want to delete this question?</p>
            <p>Deleted question cannot be recovered.</p>
          </template>
          <a-button v-show="myform.id>0" type="link">Delete</a-button>
        </a-popconfirm>
        <a-popconfirm placement="top" ok-text="Yes" cancel-text="No" @confirm="activer(myform.id)">
          <template v-slot:title>
            <p>Are you sure you want to active this question?</p>
          </template>
          <a-button v-show="myform.active==0" type="link">Active</a-button>
        </a-popconfirm>
      </a-form-item>

      <div style="text-align:center;">     
        <a-affix :offset-bottom="30" v-show="showSaveBtn">
          <a-button shape="round" type="primary" size="large" @click="saveForm()">
            Push here to save your changes before leaving
          </a-button>
        </a-affix>   
      </div>  

    </a-form>

  </div>
</div>
</template>

<script>
import {Poster,Deleter,Puter} from '../utils/request.js';
import _ from 'lodash';
import {
  VerticalAlignMiddleOutlined,
  BorderOuterOutlined,
  BorderInnerOutlined,
  BarChartOutlined,
  PieChartOutlined,
  BorderOutlined,
  PaperClipOutlined,
} from '@ant-design/icons-vue';
import { getStorage, ref, getDownloadURL, uploadBytesResumable, listAll, deleteObject } from "firebase/storage";
const storage = getStorage();
import swal from 'sweetalert2';
window.Swal = swal;
import { create, all } from 'mathjs/number'
const math = create(all)

export default {
  data(){
    return {
      myform: {
        belong: '',
        show_type: 2,
        type_id: 2,
        mainskill: 0,
        cap_id: 0,
        cap_content: '',
        id: 0,
        quest_content: '',
        level: '',
        steps: '',
        otherskill: '',
        formtricks: [],
        dsp_items: [],
        act_items: [],
        resolve: '',
      },
      dspNumNew: 4,
      dspNumCur: 0,
      actNumNew: 4,
      actNumCur: 0,
      showCap: false,
      showSaveBtn: false,
      loadingData: true,
      loadingParam: true,
      arrSkills: [],
      arrTypes: [],
      arrTricks: [],
      blurElemt: {},
      imgUrls: [],
      imgData: null,
      selImgType: 'IQ',
      selImgSize: 'M',
      selImgPos: 'R',
      arrcloze: ['C1','C2','C3','C4','C5','C6'],
      arrphoto: ['1','2','3','4','5','6'],
    };
  },

  components:{
    VerticalAlignMiddleOutlined,
    BorderOuterOutlined,
    BorderInnerOutlined,
    BarChartOutlined,
    PieChartOutlined,
    BorderOutlined,
    PaperClipOutlined,
  },

  methods: {
    putImgLink(item,size,align){
      if(this.blurElemt.name=='CAP' && item.typ == 'IQ'){
        swal.fire({text: 'IQ image should Not insert into Cap content!',
                  timer: 3000,
                  showConfirmButton: false});
        return false;
      }
      if(this.blurElemt.name!='CAP' && item.typ == 'IC'){
        swal.fire({text: 'IC image should Only insert into Cap content!',
                  timer: 3000,
                  showConfirmButton: false});
        return false;
      }
      let tmpWidth = size == 'S' ? '24%' : size == 'B' ? '68%' : '38%';
      let tmpAlign = align == 'L' ? 'float:left;margin: 0 10px 10px 0;' : align == 'R' ? 'float:right;margin: 0 0 10px 10px;' : '';
      let tag = 'img alt="'+ item.typ + item.key +'" src="'+ item.url +'" width="'+ tmpWidth +'" style="'+ tmpAlign+ 'border-radius:3px;"'
      console.log('url',item.url);
      this.putShortcut(tag);
    },

    putShortcut(tag){
      if(this.blurElemt.val!= undefined && this.blurElemt.start!= undefined){
        if(tag == this.blurElemt.name){
        swal.fire({text: '['+ tag +'] Can not calculate value by itself!',
                  icon: "error",
                  timer: 3000,
                  showConfirmButton: false});
          return;
        }
        let item = "<" + tag + ">";
        let result = this.blurElemt.val.value.substring(0,this.blurElemt.start) 
          + item + this.blurElemt.val.value.substring(this.blurElemt.end);
        this.blurElemt.val.value = result;
        switch (this.blurElemt.name) {
          case 'CNT' :
            this.myform.quest_content = result;
            break;
          case 'CAP' :
            this.myform.cap_content = result;
            break;
          case 'RSV' :
            this.myform.resolve = result;
            break;
          default :
            if(this.blurElemt.name.substring(0,1)=='A'){
              this.myform.act_items[parseInt(this.blurElemt.name.substring(1))-1].content = result;
            }else if(this.blurElemt.name.substring(0,1)=='N'){
              this.myform.act_items[parseInt(this.blurElemt.name.substring(1))-1].answer = result;
            }else if(this.blurElemt.name.substring(0,1)=='D'){
              this.myform.dsp_items[parseInt(this.blurElemt.name.substring(1))-1].content = result;
            }
        }
        this.$nextTick(()=>{
          let newEnd = this.blurElemt.start + tag.length + 2;
          this.blurElemt.val.setSelectionRange(newEnd,newEnd);
          this.blurElemt.val.focus();
          this.showSaveBtn = true;
          this.debounceChange();
        })
      }
    },

    putOtherSC(tag,num=0){
      if(this.blurElemt.val!= undefined && this.blurElemt.start!= undefined){
        let result = '';
        let abb = this.blurElemt;
        switch (tag) {
          case 'PLF' :
            result = abb.val.value.substring(0,abb.start) + '<img src="/images/LF'+ num +'.png" width="25%" style="float:left;">' +abb.val.value.substring(abb.end);
            break;
          case 'PLM' :
            result = abb.val.value.substring(0,abb.start) + '<img src="/images/LM'+ num +'.png" width="25%" style="float:left;">' +abb.val.value.substring(abb.end);
            break;
          case 'PRF' :
            result = abb.val.value.substring(0,abb.start) + '<img src="/images/RF'+ num +'.png" width="25%" style="float:right;">' +abb.val.value.substring(abb.end);
            break;
          case 'PRM' :
            result = abb.val.value.substring(0,abb.start) + '<img src="/images/RM'+ num +'.png" width="25%" style="float:right;">' +abb.val.value.substring(abb.end);
            break;
          case 'Cen' :
            result = abb.val.value.substring(0,abb.start) + '<center> ' 
              + abb.val.value.substring(abb.start,abb.end) + ' </center>' +abb.val.value.substring(abb.end);
            break;
          case 'Dv1' :
            result = abb.val.value.substring(0,abb.start) + '<div class="dv1"><p>\n' 
              + abb.val.value.substring(abb.start,abb.end) + '\n</div>' +abb.val.value.substring(abb.end);
            break;
          case 'Qts' :
            result = abb.val.value.substring(0,abb.start) + '<quotespan>' 
              + abb.val.value.substring(abb.start,abb.end) + '</quotespan>' +abb.val.value.substring(abb.end);
            break;
          case 'Tb1' :
            result = abb.val.value.substring(0,abb.start)
              + '<table class="tb1">\n'
              + '<tr><th align="left">Title A</th><th align="left">Title B</th></tr>\n'
              + '<tr><td align="left">Content A1</td><td align="left">Content B1</td></tr>\n'
              + '<tr><td align="left">Content A2</td><td align="left">Content B2</td></tr>\n'
              + '</table>\n\n' 
              + abb.val.value.substring(abb.end);
            break;
          case 'Tb9' :
            result = abb.val.value.substring(0,abb.start)
              + '<table class="tb9">\n'
              + '<tr><th align="left">Title A</th><th align="left">Title B</th></tr>\n'
              + '<tr><td align="left">Content A1</td><td align="left">Content B1</td></tr>\n'
              + '<tr><td align="left">Content A2</td><td align="left">Content B2</td></tr>\n'
              + '</table>\n\n' 
              + abb.val.value.substring(abb.end);
            break;
          case 'Bar' :
            result = abb.val.value.substring(0,abb.start)
              + '@#BAR;S1|BAR;S2;10%;30%#\n'
              + 'CAT;School Years;CEN;25;0;SPL;;;0\n'
              + 'VAL;Subjects;CEN;25;10;SPL;MSP;NOL;0\n'
              + '#XY;Sport;Music\n'
              + 'Year 1;20;40\n'
              + 'Year 2;15;35\n'
              + 'Year 3;40;25\n'
              + 'Year 4;50;30\n'
              + '#This is title;;50;5;60;40\n'
              + 'HOR;55%;10%#@\n\n'
              + abb.val.value.substring(abb.end);
            break;
          case 'Pie' :
            result = abb.val.value.substring(0,abb.start)
              + '@#PIE;55%;50%;0;67%;PER;IN#\n'
              + 'NA\n'
              + '#Maths;10\n'
              + 'English;20\n'
              + 'Sport;30\n'
              + 'Music;25\n'
              + '#This is title;;50;5;60;40\n'
              + 'VER;5%;25%#@\n\n'
              + abb.val.value.substring(abb.end);
            break;
        }
        let oldLength = abb.val.value.length;
        abb.val.value = result;
        let newEnd = result.length - oldLength + abb.end;
        switch (abb.name) {
          case 'CNT' :
            this.myform.quest_content = result;
            break;
          case 'CAP' :
            this.myform.cap_content = result;
            break;
          case 'RSV' :
            this.myform.resolve = result;
            break;
        }
        this.$nextTick(()=>{
          abb.val.setSelectionRange(newEnd,newEnd);
          abb.val.focus();
          this.showSaveBtn = true;
          this.debounceChange();
        })
      }
    },

    handleBlur(ev,name){
      this.blurElemt.start = ev.srcElement.selectionStart;
      this.blurElemt.end = ev.srcElement.selectionEnd;
      this.blurElemt.val = ev.srcElement;
      this.blurElemt.name = name;
    },

    onoffshowCap() {
      this.showCap =!this.showCap;
      if(!this.showCap){
        this.myform.cap_content = '';
      }
      this.listAllImgs();
    },
    
    saveForm(){
      if(this.dataCheck()){
        let postform =  JSON.parse(JSON.stringify(this.myform));
        if(postform.formtricks){
          postform.tricks =  postform.formtricks.join("|");
        }else{
          postform.tricks =  '';
        }
        let splits = 0;
        let odsplits = 0;
        let arrSplitNR = []; // 记录每组NR的可用数量
        let arrCountNR = []; // 记录每组NR的已用数量
        if(this.myform.dsp_items && this.myform.dsp_items.length>0){ // 检查split数目
          splits = 1;
          this.myform.dsp_items.forEach((item,index)=>{
            if(/^RC:/.test(item.content)){
              let arrSecond = item.content.replace(/^RC:/,'').split(";");
              splits = splits * arrSecond.length;
            }
            if(/^NR:.+;.+$/.test(item.content)){ // NR首个
              let arrSecond = item.content.replace(/^NR:/,'').split(";");
              arrSplitNR[index+1] = arrSecond.length;
              arrCountNR[index+1] = 1;
            }
            if(/^NR:<D[1-9][0-9]?>/.test(item.content)){
              let thisNrFrom = 0;
                item.content.replace(/^NR:<D([1-9][0-9]?)>$/,(match,p1)=>{
                thisNrFrom = p1;
              })
              arrCountNR[thisNrFrom] += 1;
            }
            if(/^OD:/.test(item.content)){
              let arrSecond = item.content.replace(/^OD:/,'').split(";");
              odsplits = arrSecond.length;
            }
          })
        }
        arrCountNR.forEach((value,key)=>{
          if(value>0){
            splits = splits * math.factorial(arrSplitNR[key]) / math.factorial(arrSplitNR[key]-value);
          }
        })
        postform.splits = odsplits>0 ? odsplits : splits;

        console.log('Save form',postform);
        Poster('questone',postform)
        .then(res => {
          console.log('From Backend', res.data);
          this.showSaveBtn = false;
          swal.fire({text: "This question has been saved to database!",
                    timer: 3000,
                    icon: "success",
                    showConfirmButton: false});
          //this.myform = res.data; 
          this.refreshEditer(res.data); // get the data back
          let newdata = JSON.parse(JSON.stringify(res.data));
          this.$store.commit('setshowdata', newdata);
          this.$store.commit('refreshlist', 0);
        })
        .catch(err => {
          console.log(err);
          swal.fire({text: "Network operation error, please ask system manager for help!",
                    timer: 3000,
                    icon: "error",
                    showConfirmButton: false});
        });
      }
    },

    dataCheck(){
      let arrTmpChecks = ['mainskill','quest_content','level','steps','resolve'];
      let strEptPromp = '';
      let strHanPromp = '';
      let intODNumber = 0;
      let intRCnNR = 0;
      let regHan = /[\u3002\uff1b\uff0c\uff1a\u201c\u201d\uff08\uff09\u3001\uff1f\u300a\u300b]/u;

      arrTmpChecks.forEach((value)=>{
        if((this.myform[value]+"").trim()==''){
          strEptPromp += '[' + value.slice(0,1).toUpperCase() + value.slice(1) + '] ';
        }
        if(regHan.test(this.myform[value]+"")){
          strHanPromp += '[' + value.slice(0,1).toUpperCase() + value.slice(1) + '] ';
        }
      })
      if(this.myform.dsp_items){
        this.myform.dsp_items.forEach((value,index)=>{
          if(value.content.trim()==''){
            strEptPromp += '[DSP'+ (index+1) + ' Content] ';
          }
          if(regHan.test(value.content)){
            strHanPromp += '[DSP'+ (index+1) + ' Content] ';
          }
          if(regHan.test(value.mate)){
            strHanPromp += '[DSP'+ (index+1) + ' Mate] ';
          }
          if(regHan.test(value.answer)){
            strHanPromp += '[DSP'+ (index+1) + ' Answer] ';
          }
        })
      }
      if(this.myform.act_items){
        this.myform.act_items.forEach((value,index)=>{
          if(value.content.trim()==''){
            strEptPromp += '[ACT'+ (index+1) + ' Content] ';
          }
          if(regHan.test(value.content)){
            strHanPromp += '[ACT'+ (index+1) + ' Content] ';
          }
          if(regHan.test(value.mate)){
            strHanPromp += '[ACT'+ (index+1) + ' Mate] ';
          }
          if(regHan.test(value.answer)){
            strHanPromp += '[ACT'+ (index+1) + ' Answer] ';
          }
        })
      }
      if(strEptPromp!=''){
        swal.fire({text: strEptPromp + 'should not be empty!',
                  timer: 3000,
                  showConfirmButton: false});
        return false;
      }else if(strHanPromp!=''){
        swal.fire({text: strHanPromp + 'should not contain any chinese punctuation!',
                  timer: 3000,
                  showConfirmButton: false});
        return false;
      }else{
        if(/@\d@&/.test(this.myform.quest_content)){
          let arrConCan = [];
          let arrActCan = [];
          this.myform.quest_content.replace(/@(\d)@&/g,(match,p1)=>{
            arrConCan.push(p1); 
          });
          arrConCan.sort(function(a, b){return a - b}); 
          this.myform.act_items.forEach((value)=>{
            arrActCan.push(value.content);
          });
          arrActCan.sort(function(a, b){return a - b}); 
          if(!(JSON.stringify(arrConCan)===JSON.stringify(arrActCan))){
            swal.fire({text: 'Canvas Answer in content does not match Actitems!',
                    timer: 3000,
                    showConfirmButton: false});
            return false;
          }
        }
        if(this.myform.quest_content.length>4000){
          swal.fire({text: '[Question Content] should not longer than 4000 characters!',
                  timer: 3000,
                  showConfirmButton: false});
          return false;
        }
        if(this.myform.cap_content.length>10000){
          swal.fire({text: '[Cap Content] should not longer than 10000 characters!',
                  timer: 3000,
                  showConfirmButton: false});
          return false;
        }
        if(this.myform.resolve.length>4000){
          swal.fire({text: '[Resolve] should not longer than 4000 characters!',
                  timer: 3000,
                  showConfirmButton: false});
          return false;
        }
        if(this.myform.dsp_items){
          let intSplitNum = 0;
          let index = 0;
          for(let value of this.myform.dsp_items){
            if(value.content.length>1000){
              swal.fire({text: '[DSP'+ (index+1) + ' Content] should not longer than 1000 characters!',
                        timer: 3000,
                        showConfirmButton: false});
              return false;
            }
            if(value.cited==4){
              swal.fire({text: '<D'+ (index+1) +'>' + ' has some cite problem to solve before saved!',
                        timer: 3000,
                        showConfirmButton: false});  
              return false;
            }
            if(/^OD:/.test(value.content)){
              if(/^OD:([^;]+;)+[^;]+/.test(value.content)){
                let secondStr = value.content.trim().replace('OD:','');
                let secondArr = secondStr.split(";");
                if(intSplitNum==0){
                  intSplitNum = secondArr.length;
                }else if(intSplitNum!=secondArr.length){
                  swal.fire({text: '[DSP'+ (index+1) + ' Content] Each OD should have equal items quantity!',
                            timer: 3000,
                            showConfirmButton: false});
                  return false;
                }
              }else{
                swal.fire({text: '[DSP'+ (index+1) + ' Content] Please check your OD input format!',
                          timer: 3000,
                          showConfirmButton: false});
                return false;
              }
              intODNumber ++;
            }
            if(/^RC:|^NR:/.test(value.content)){
              intRCnNR ++;
            }
            if(intODNumber > 0 && intRCnNR > 0){
              swal.fire({text: '[DSP'+ (index+1) + ' Content] OD can not use with RC or NR!',
                        timer: 3000,
                        showConfirmButton: false});
              return false;
            }
            
            if(/^OD:|^RC:|^NR:|^RD:/.test(value.content) && /;$|;[ ]*;|:[ ]*;/.test(value.content)){
              swal.fire({text: '[DSP'+ (index+1) + ' Content] including blank option(s)!',
                        timer: 3000,
                        showConfirmButton: false});
              return false;
            }

            if(/(^OD:|^RC:|^NR:|^RD:)[^0-9]*:[^0-9]*/.test(value.content)){
              swal.fire({text: '[DSP'+ (index+1) + ' Content] including extra colon!',
                        timer: 3000,
                        showConfirmButton: false});
              return false;
            }
           
            index++;
          }
        }
        if(this.myform.act_items){
          let acterr = 0;
          this.myform.act_items.forEach((value,index)=>{
            if(value.content.length>1000){
              swal.fire({text: '[ACT'+ (index+1) + ' Content] should not longer than 1000 characters!',
                        timer: 3000,
                        showConfirmButton: false});
              acterr = 1;
            }
            if(value.answer.length<1){
              swal.fire({text: '[ACT'+ (index+1) + ' Answer] should not be empty!',
                        timer: 3000,
                        showConfirmButton: false});
              acterr = 1;
            }
          })
          if(acterr>0){
            return false;
          }
        }
        let reg = '';
        reg = /^[1-9](.[0-9])?$/;
        if(!reg.test(this.myform.level)){
          swal.fire({text: '[Level] should be a number from 1 to 9.9!',
                    timer: 3000,
                    showConfirmButton: false});
          return false;
        }
        reg = /^\d$/;
        if(!reg.test(this.myform.steps)){
          swal.fire({text: '[Steps] should be a number from 0 to 9!',
                    timer: 3000,
                    showConfirmButton: false});
          return false;
        }
        return true;
      }
    },

    delter(){
      let delImg = false;
      let popImg = '';
      if(this.myform.id>0){
        this.imgUrls.forEach(item=>{
          if(item.typ=='IC' && this.myform.sibcount<1 && this.myform.cap_id>0){
            popImg = ' [IC]';
            delImg = true;
          }else if(item.typ=='IQ'){
            popImg = ' [IQ]';
            delImg = true;
          }
        })
        if(delImg){
          swal.fire({text: 'Please delete all'+ popImg + ' images before question action!',
                    timer: 3000,
                    icon: "warning",
                    showConfirmButton: false});
          return false;
        }
        Deleter(`questone`,{'id': this.myform.id,'cap_id': this.myform.cap_id })
        .then(res => {
          console.log('Dleter',res.data);
            this.resetAll();
        })
      }
    },

    activer(getid){
      if(this.showSaveBtn){
        swal.fire({text: 'Please save you changes before activate!',
          icon: "warning",
          timer: 3000,
          showConfirmButton: false});
      }else{
        if(this.myform.type_id==2 || this.myform.type_id==22){
          let tmpYes = 0;
          let tmpNo = 0;
          let tmpMxx = 0;
          this.myform.act_items.forEach((item)=>{
            if(/^(MA|MI|EQU|GTE|LTE)/.test(item.answer)){
              tmpMxx += 1;
            }else if(item.answer=='Y'){
              tmpYes += 1;
            }else if(item.answer=='N'){
              tmpNo += 1;
            } 
          });
          if((tmpYes!=1 || tmpNo!=(this.myform.act_items.length-1)) && tmpMxx<1){
            swal.fire({text: "There must be one and only one 'Y' answer, the others should be 'N' answers. (formatAnswer excepted)",
              icon: "warning",
              timer: 3000,
            });
            return false;
          }    
        }
        Puter(`questone`,{ 'id': getid, 'act': '1' })
        .then(res => {
          console.log('Clicked Active',res.data);
          this.resetAll();
        })
        .catch(err => {
          console.log(err);
        });
      }
    },

    resetAll(){
      let objTmp = {};
      objTmp.belong = this.$store.state.listskill.bl;
      objTmp.show_type = 2;
      objTmp.type_id = 2;
      objTmp.mainskill = parseInt(this.$store.state.listskill.sk);
      objTmp.id = '';
      objTmp.cap_id = '';
      objTmp.dsp_items = [];
      objTmp.act_items = [];
      objTmp.quest_content = '';
      objTmp.cap_content = '';
      objTmp.resolve = '';
      objTmp.otherskill = 0;
      objTmp.tricks = '';
      this.$store.commit('setshowdata', objTmp);
      this.$store.commit('refreshlist', 1);
      let newdata = JSON.parse(JSON.stringify(objTmp));
      this.$store.commit('seteditdata', newdata);
    },

    onChangeForm() {
      this.showSaveBtn = true;
      this.debounceChange();
    },

    debounceChange: _.debounce(function () {
      if(/@\d@&/.test(this.myform.quest_content)){
        let arrConCan = [];
        let arrActCan = [];
        this.myform.quest_content.replace(/@(\d)@&/g,(match,p1)=>{
          arrConCan.push(p1); 
        });
        arrConCan.sort(function(a, b){return a - b}); 
        this.myform.act_items.forEach((value)=>{
          arrActCan.push(value.content);
        });
        arrActCan.sort(function(a, b){return a - b}); 
        if(!(JSON.stringify(arrConCan)===JSON.stringify(arrActCan))){
          swal.fire({text: 'Canvas Answer in content does not match Actitems!',
                  timer: 3000,
                  showConfirmButton: false});
          return false;
        }
      }
      let newdata = JSON.parse(JSON.stringify(this.myform));
      this.$store.commit('setshowdata', newdata);
      this.checkCited();
    }, 3000),

    checkCited(){
      //let noCited=['ALG','FIR','WOR','WEK','MON','MOS','ROM','THM','T12','T24','RAT'];
      let fraCited =['FRP','FRI','FRR'];
      if(this.myform.dsp_items){
        this.myform.dsp_items.forEach((value,index)=>{
          let reg = RegExp('<D'+ (index+1) +'>');
          value.cited = 0;
          if(reg.test(this.myform.quest_content)){
            value.cited = 1;
          }
          if(reg.test(this.myform.resolve)){
            value.cited = 1;
          }
          for(let i=index;i<this.myform.dsp_items.length;i++){  // 检查 DSP 字符型 再引用
            if(reg.test(this.myform.dsp_items[i].content)){
              if((fraCited.indexOf(value.content.substring(0,3))>=0 &&
               !(/^FR\w=|^RAT=/.test(this.myform.dsp_items[i].content) || !/=/.test(this.myform.dsp_items[i].content)) )) {
                value.cited = 4;
                swal.fire({text: 'As [' + value.content.substring(0,3) + '], <D'+ (index+1) +'>' + ' should not be cited by '+'<D'+ (i+1) +'>',
                          timer: 5000,
                          icon: "warning",
                          showConfirmButton: false});              
              }else{
                value.cited = 1;
              }
            }
          }
          for(let i=0;i<this.myform.act_items.length;i++){    // 检查 ACT 字符型 再引用
            if(reg.test(this.myform.act_items[i].content)){
              if((fraCited.indexOf(value.content.substring(0,3))>=0 &&
               !(/^FR\w=|^RAT=/.test(this.myform.act_items[i].content) || !/=/.test(this.myform.act_items[i].content)) )) {
                value.cited = 4;
                swal.fire({text: 'As [' + value.content.substring(0,3) + '], <D'+ (index+1) +'>' + ' should not be cited by '+'<A'+ (i+1) +'>',
                          timer: 5000,
                          icon: "warning",
                          showConfirmButton: false});              
              }else{
                value.cited = 1;
              }
            }
            if(reg.test(this.myform.act_items[i].answer)){
              value.cited = 1;
            }
          }          
          for(let i=0;i<index;i++){
            if(reg.test(this.myform.dsp_items[i].content)){  // 检查 前 引用 后
              value.cited = 4;
              swal.fire({text: '<D'+ (index+1) +'>' + ' should not be cited by '+'<D'+ (i+1) +'>',
                        timer: 5000,
                        icon: "warning",
                        showConfirmButton: false});              
            }
          }
        })
      }

    },

    resetItems(type,NewNum=0) {
      let NumCur = type + 'NumCur';
      let theitems = type + '_items';
      let intNewNum = parseInt(NewNum);
      let intCurNum = parseInt(this[NumCur]);
      if(intNewNum > intCurNum){
        for(let i = intCurNum ; i < intNewNum ; i++){
          if(type == 'dsp'){
            this.myform[theitems].push({
              content: '',
              key: 'dsp'+i,
              cited: 0,
            });
          }else{
            this.myform[theitems].push({
              content: '',
              mate: '',
              answer: '',
              trick: '',
              key: 'act'+i,
              cited: 0,
            });
          }
        }
        this[NumCur] = intNewNum;
      }else if(intNewNum < intCurNum){
        this.myform[theitems].splice(intNewNum, intCurNum - intNewNum);
        this[NumCur] = intNewNum;
      }
    },

    chooseImg(type) {
      this.selImgType = type;
      if(this.myform.id > 0){
        this.$refs.inputImg.click()   
      }else{
        swal.fire({text: 'Please save this question first and then you can upload image!',
                  timer: 3000,
                  icon: "warning",
                  showConfirmButton: false});
      }
    },

    previewImg(event) {
      this.imgData = event.target.files[0];
      if(this.imgData){
        this.uploadImg();        
      }
    },

    uploadImg(){
      let imgNext = 1;
      this.imgUrls.forEach(item=>{
        if(item.typ==this.selImgType && imgNext==item.key){
          imgNext++;
        }
      });
      let newImgPath = '';
      if(this.selImgType=='IC'){
        newImgPath = (2000000 + this.myform.cap_id) + '/' + imgNext + '.jpg';
      }else{
        newImgPath = (1000000 + this.myform.id) + '/' + imgNext + '.jpg';
      }
      var uploadTask = uploadBytesResumable(ref(storage, newImgPath), this.imgData);
      //var uploadTask =  fb.storage().ref(newImgPath).put(this.imgData);
      uploadTask.on('state_changed',function(snapshot){
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log('Upload is ' + progress + '% done');
      },function(error) {
        console.log('upload error:',error);
      }, ()=>{
        // Upload completed successfully, now we can get the download URL
        getDownloadURL(uploadTask.snapshot.ref).then((newurl)=>{
          this.imgUrls.push({
            url:newurl,
            key:imgNext,
            typ:this.selImgType
            });
          console.log('File available at:', newurl);
        });
      });
    },

    listAllImgs(){
      this.imgUrls = [];
      if (this.myform.cap_id>0){
        this.listFbImg('IC');
      }
      this.listFbImg('IQ');
    },

    listFbImg(type){
      let tmpImgDir = '';
      if(this.myform.id > 0 && type=='IQ'){
          tmpImgDir = 1000000 + this.myform.id;
      }else if(this.myform.cap_id > 0 && type=='IC'){
          tmpImgDir = 2000000 + this.myform.cap_id;
      }
      if(tmpImgDir!=''){
        let listRef = ref(storage, tmpImgDir.toString());
        listAll(listRef).then(res=>{
          res.items.forEach(itemRef=>{
            let imgNum = parseInt(itemRef.name.replace(/(\w+)(.jpg|.jpeg|.png|.gif)/i,'$1'));
            getDownloadURL(itemRef).then(geturl=>{
              this.imgUrls.push({
                url:geturl,
                key:imgNum,
                typ:type
              });
            });
          });
          this.debounceOrderImg();
        }).catch(function(error) {
          console.log('download images error:',error);
        });
      }
    },

    debounceOrderImg: _.debounce(function(){
      this.imgUrls.sort(function(a, b){
        let intA = a.typ=='IC'? 0 : 1000;
        let intB = b.typ=='IC'? 0 : 1000;
        return a.key + intA - b.key - intB;
      });
    }, 500),

    delFbImg(item,index){
      let tmpImgDir = '';
      if(item.typ=='IQ'){
        tmpImgDir = 1000000 + this.myform.id;
      }else if(item.typ=='IC'){
        tmpImgDir = 2000000 + this.myform.cap_id;
      }
      let tmpPath = tmpImgDir + '/' + item.key + '.jpg';
      console.log('delete',tmpPath);
      deleteObject(ref(storage, tmpPath)).then(()=>{
        this.imgUrls.splice(index,1)
        this.listAllImgs();
        swal.fire({title: 'Image deleted',
                  text: 'please remove the image link at contents manually!',
                  timer: 3000,
                  showConfirmButton: false});
      }).catch(function(error) {
        console.log('delete image error:',error);
      });

    },

    refreshEditer(newdata){
      this.myform = newdata;
      if(this.myform.tricks){
        this.myform.formtricks = this.myform.tricks.split("|");
      }else{
        this.myform.formtricks = [];
      }
      this.actNumCur = this.myform.act_items.length;
      this.actNumNew = this.actNumCur>0 ? this.actNumCur : this.actNumNew;
      this.dspNumCur = this.myform.dsp_items.length;
      this.dspNumNew = this.dspNumCur>0 ? this.dspNumCur : this.dspNumNew;

      if (this.myform.cap_id < 1){
        this.showCap = false;
      }else{
        this.showCap = true;
      }
      if(!this.myform.id && this.myform.quest_content=='' && this.myform.mainskill>=150 && this.myform.mainskill<200){
        this.myform.show_type = 1;
      }
      this.showSaveBtn = false;
      this.listAllImgs();
      this.loadingData = false;
    },

  },

  watch: {
    '$store.state.editdata': function () {
      console.log('Editer received data',this.$store.state.editdata);
      if(Object.keys(this.$store.state.editdata).length){
        this.refreshEditer(this.$store.state.editdata);
      }
      
    },

    '$store.state.sysparam': function () {
      this.arrSkills = this.$store.state.sysparam[0];
      this.arrTypes = this.$store.state.sysparam[1];
      this.arrTricks = this.$store.state.sysparam[2];
      this.loadingParam = false;
    },

    '$store.state.listskill': function () {
      if(!this.showSaveBtn && this.myform.id<1){
        this.myform.mainskill = this.$store.state.listskill.sk;
        this.myform.belong = this.$store.state.listskill.bl;
      }
      if(!this.myform.id && this.myform.quest_content=='' && this.myform.mainskill>=150 && this.myform.mainskill<200){
        this.myform.show_type = 1;
      }
    }

  }

};
</script>

<style scoped>
#shortcut {
  padding: 4px;
  margin: 2px 2px 2px 10px;
  border-width: 1px;
  border-style: solid; 
  border-color: gray; 
  background-color:#ECEFF1;
  border-radius: 4px; 
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.sc_gap {
  margin-right: 8px;
}
#edit-box{
  padding: 16px 16px;
  margin: 2px 2px 2px 10px;
  border-width: 1px;
  border-style: solid; 
  border-color: gray; 
  border-radius: 4px; 
}
.imgBox{
  width: 100%;
  height: 100%;
  text-align: center;
}
.imgPic{
  width: 100%;
  height: 100%;
  border-radius: 4px;
}
.imgPic:hover{
  border: 1px dashed #03A9F4;
  cursor:pointer;
}
.imgRow{
  background-color: #ECEFF1;
  margin-bottom: 8px;
}
.imgCol{
  height: 100%;
  padding: 2px;
}
.text_input{
  font-family: monospace;
}
.dsp_div{
  font-family: monospace;
  padding:0px;
  margin-bottom:4px;
}
.dsp_div0{
  padding:0px;
  margin-bottom:4px;
  border-left:5px solid orange;
}
.dsp_div4{
  padding:0px;
  margin-bottom:4px;
  border-left:5px solid red;
}
</style>
