<template>
  <div style="padding: 0 8px;">=== Selctive === <span class="ref1" @click="loadData()">↻</span></div>
  <a-tree style="backgroundColor:#90A4AE;" :tree-data="treeData"  @select="onSelect" />
  <div style="padding: 0 8px;">==== OC ==== <span class="ref1" @click="loadData()">↻</span></div>
  <a-tree style="backgroundColor:#BCAAA4;" :tree-data="treeDataOC"  @select="onSelect" />
</template>

<script>
import {Geter} from '../utils/request.js';

export default {
  name: 'skilltree',
  data() {
    return {
      treeData: [],
      treeDataOC: [],
      selSS: [],
      selOC: [],
    };
  },
  mounted(){
    this.loadData();
  },
  methods: {
    loadData(){
      Geter(`testoption`)
      .then(res => {
        let arrtmptree = [];
        let arrSkills = [];
        let arrTypes = [];
        let arrTricks = [];
        let arrOptions = [];
        let arrCascades = [];

        for(let i=0;i<2;i++){
          let belong = i==0? 'SS' : 'OC';
          let thisTree = [];
          res.data[0].forEach((value)=>{  // 树结构第一层
            let tmpCt = i==0 ? value.countSS : value.countOC;
            let tmpAt = i==0 ? value.actSS : value.actOC;
            let tmpCp = i==0 ? value.countPS : value.countPC;
            let tmpAp = i==0 ? value.actPS : value.actPC;
            if(!tmpCt) tmpCt = 0;
            if(!tmpAt) tmpAt = 0;
            if(!tmpCp) tmpCp = 0;
            if(!tmpAp) tmpAp = 0;
            if(value.parent==0){
              thisTree.push({
                title: value.name,
                key: belong + value.id,
                selectable: false,
                children: []
              })
            }else{
              if(arrtmptree[belong + value.parent]){    // 第一个
                arrtmptree[belong + value.parent].push({
                  title: value.name, // +strCt
                  key: belong + value.id,
                  count: tmpCt,
                  act: tmpAt,
                  countpre: tmpCp,
                  actpre: tmpAp,
                  children: []
                })
              }else{     // 后面的
                arrtmptree[belong + value.parent] = [];
                arrtmptree[belong + value.parent].push({
                  title: value.name, 
                  key: belong + value.id,
                  count: tmpCt,
                  act: tmpAt,
                  countpre: tmpCp,
                  actpre: tmpAp,
                  children: []
                })
              }
            }
          })

          thisTree.forEach((value)=>{   // 挂子孙节点，另外理出一个单结构arrSkills
            if(i==0) arrSkills.push({
              key: parseInt(value.key.substring(2)),
              slc: 0,
              name: value.title
            });
            let topCascade = value.title + ' ⇾ ';
            if(arrtmptree[value.key]){
              value.children = arrtmptree[value.key];  // 挂子节点
              value.children.forEach((value)=>{
                value.selectable = false;
                if(i==0) arrSkills.push({
                  key: parseInt(value.key.substring(2)),
                  slc: 0,
                  name: value.title
                });
                let midCascade = value.title + ' ⇾ ';
                if(arrtmptree[value.key]){
                  let sumCt = 0;
                  let sumAt = 0;
                  let sumCp = 0;
                  let sumAp = 0;
                  value.children = arrtmptree[value.key];  // 挂孙节点
                  value.children.forEach((value)=>{
                    sumCt += value.count;
                    sumAt += value.act;
                    sumCp += value.countpre;
                    sumAp += value.actpre;
                    if(i==0) arrCascades[parseInt(value.key.substring(2))] = topCascade + midCascade + value.title; // 层叠记录数组
                    if(i==0) arrSkills.push({
                      key: parseInt(value.key.substring(2)),
                      slc: 1,
                      name: value.title,                  
                    });
                    value.title += ' '+value.count+(value.act>0?'('+value.act+')':'')+(value.countpre>0?'|'+value.countpre:'')+(value.actpre>0?'('+value.actpre+')':'');
                  })
                  value.title = value.title + ' ' + sumCt + (sumAt>0?'('+sumAt+')':'') + (sumCp>0?'|'+sumCp:'') + (sumAp>0?'('+sumAp+')':'');  // 子节点加上累计计数
                }
              })
            }
          })

          if(i==0) this.treeData = Array.from(thisTree);
          if(i==1) this.treeDataOC = Array.from(thisTree);
        }

        res.data[1].forEach((value)=>{
          arrTypes.push({
            key: value.id,
            name: value.name
          });
        })

        res.data[2].forEach((value)=>{
          arrTricks.push({
            key: value.id,
            name: value.name
          });
        })

        arrOptions[0] = arrSkills; 
        arrOptions[1] = arrTypes;
        arrOptions[2] = arrTricks;
        this.$store.commit('setsysparam', arrOptions);
        this.$store.commit('setskillcasc', arrCascades);

      })
      .catch(err => {
        console.log(err);
      });
    },

    onSelect(selectedKeys) {
      let skill = parseInt(selectedKeys[0].substring(2));
      let belong = selectedKeys[0].substring(0,2);
      if(skill<1){
        skill = parseInt(this.$store.state.listskill.sk);
      }
      this.$store.commit('setlistskill', {sk:skill,bl:belong});
      localStorage.setItem('localskill', JSON.stringify({sk:skill,bl:belong}));
    },
  },
}

</script>

<style scoped>
.ref1{
  font-size: 16px;
}
.ref1:hover{
  cursor:pointer;
}
</style>