<template>
  <a-layout id="components-layout-demo-side" style="min-height:100vh;width:100%;">
    <a-layout-sider width="200" :collapsedWidth="clspwidth" v-model:collapsed="collapsed" collapsible @click="offPoperSp">
      <div class="logo">
        <img v-if="clspwidth>50" alt="logo" src="../assets/logo.png" height='40' :width="collapsed?44:118" style="border-radius:6px; object-fit: cover;object-position: 4% 0%;">
      </div>
      <a-menu mode="inline" v-model:selectedKeys="selectedKeys">
        <a-menu-item key="1">
          <setting-outlined />
          <span>System</span>
        </a-menu-item>
        <a-menu-item key="3">
          <desktop-outlined />
          <span>Tests</span>
        </a-menu-item>
        <a-menu-item key="5">
          <team-outlined />
          <span>Users</span>
        </a-menu-item>
        <a-menu-item key="7">
          <ShoppingCartOutlined />
          <span>Orders</span>
        </a-menu-item>
        <a-menu-item key="9">
          <pie-chart-outlined />
          <span>Questions
          </span>
        </a-menu-item>
      </a-menu>

      <skilltree v-if="selectedKeys=='9'" />
    </a-layout-sider>

    <a-layout>
      <a-layout-header style="color:#f0f0f0;height:32px;" >
        <a-row>
          <a-col @click="offPoperSp" :span="spanLeft" >   
          <div id="loginuser">
            <span><UserOutlined /></span><span v-if="clspwidth>50">{{ $store.state.loginuser.email }} </span>
            <div class="ly_gap"> </div> 
            <a-button type="primary" size="small" @click="logOut">Log out</a-button>
            <div class="ly_gap"> </div> 
            <div v-if="selectedKeys=='9'">
              <a-radio-group name="screenSP" v-model:value="screenSP" buttonStyle="solid" size="small" @change="setScreen">
                <a-radio-button value="12">
                  1:2
                </a-radio-button>
                <a-radio-button value="0">
                  1:1
                </a-radio-button>
                <a-radio-button value="21">
                  2:1
                </a-radio-button>
              </a-radio-group>
            </div>           
          </div> 
          </a-col>

          <a-col id="show-box" :span="spanRight" v-if="selectedKeys=='9'" >   
          <a-affix :offset-top="4" >
            <div id='category'>
              {{$store.state.listskill.bl+' ⇾ '+$store.state.skillcasc[$store.state.listskill.sk]}}
            </div>
          </a-affix>
          </a-col>

        </a-row>    
      </a-layout-header>

      <a-layout-content style="margin: 0px;width: 100%;">
        <a-row type="flex">
          <a-col id="show-box" :span="24" v-if="selectedKeys=='1'">   
             <system />
          </a-col>             
          <a-col id="show-box" :span="24" v-if="selectedKeys=='3'">   
             <testlist />
          </a-col>    
          <a-col id="show-box" :span="24" v-if="selectedKeys=='5'">   
             <users />
          </a-col>            
          <a-col id="show-box" :span="24" v-if="selectedKeys=='7'">   
             <ordercheck />
          </a-col>    

          <a-col @click="offPoperSp" :span="spanLeft" v-if="selectedKeys=='9'">   
            <div id="top-box">            
              <questlist />
            </div>              
            <div id="bot-box">
              <questedit />
            </div>
          </a-col>

          <a-col id="show-box" :span="spanRight" flex="auto" v-if="selectedKeys=='9'">   
            <a-affix :offset-top="32" v-if="allowshow">
             <questshow />
            </a-affix>
          </a-col>

        </a-row>      
      </a-layout-content>
      <a-layout-footer style="text-align: center;">
      </a-layout-footer>
    </a-layout>
  </a-layout>
</template>
<script>

import {PieChartOutlined,DesktopOutlined,UserOutlined,TeamOutlined,SettingOutlined,ShoppingCartOutlined} from '@ant-design/icons-vue';
import questlist from './questlist.vue';
import questedit from './questedit.vue';
import questshow from './questshow.vue';
import skilltree from './skilltree.vue';
import ordercheck from './ordercheck.vue';
import testlist from './testlist.vue';
import system from './system.vue';
import users from './users.vue';
import { auth } from '../utils/firebase.js';
import { signOut } from "firebase/auth";

export default {
  components: {
    PieChartOutlined,
    DesktopOutlined,
    UserOutlined,
    TeamOutlined,
    SettingOutlined,
    ShoppingCartOutlined,
    questlist,
    questedit,
    questshow,
    skilltree,
    ordercheck,
    testlist,
    users,
    system,
  },
  data(){
    return{
      clspwidth: 70,
      collapsed: false,
      imgwidth: 120,
      bolLogin: false,  
      screenSP: '0',
      spanLeft: 12,
      spanRight: 12,
      selectedKeys: ["3"],
      allowshow: true,
      windowWidth: document.documentElement.clientWidth, //实时屏幕宽度
    }
  },

  mounted() {
    this.phonesize();
    window.onresize = () => {
      return (() => {
        let fullWidth = document.documentElement.clientWidth;
        this.windowWidth = fullWidth; 
        this.phonesize();
      })();
    };
  },

  methods:{
    phonesize(){
      if(this.windowWidth<600){
        this.collapsed = true;
        this.clspwidth = 30;
        this.selectedKeys = ["7"];
      }
    },

    showscreen(){
      this.allowshow=true;
    },

    setScreen(){
      this.allowshow = false;
      setTimeout(this.showscreen,1000)
      if(this.screenSP=='12'){
        this.spanRight = 16;
        this.spanLeft = 8;
      }else if(this.screenSP=='21'){
        this.spanRight = 8;        
        this.spanLeft = 16;
      }else{
        this.spanRight = 12;        
        this.spanLeft = 12;
      }
    },

    offPoperSp(){
      this.$store.commit('offpopsp');      
    },

    logOut(){
      signOut(auth).then(()=>{
        this.$store.commit('setloginuser',null);      
      }).catch(function(error) {
        console.log(error);
      });
    }
  },

};
</script>


<style>
#components-layout-demo-side .logo {
  height: 32px;
  margin: 16px;
}
#companyname{
  color: rgb(225, 223, 235);
}
#loginuser{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
#category {
  color: black;
  font-weight: bolder;
  line-height: 24px;
  margin: 0 8px;
  padding: 0 32px;
  text-shadow: -1px -1px 0 #f0f2f5, 
               1px -1px 0 #f0f2f5,
              -1px 1px 0 #f0f2f5, 
              1px 1px 0 #f0f2f5;
}
#show-box{
  flex-grow:1;
}
.ly_gap {
  margin-right: 32px;
}
</style>