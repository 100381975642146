<template>
  <div class="orderlist">
    <div id="usernotes" v-show="shownotes">
      <b>ID:</b>{{noteitem.id}}&nbsp;
      <span @dblclick="chgLang(noteitem.id,noteitem.idx)" v-if="noteitem.lang==1" style="font-size:12px;color:black;background-color: pink;border:3px pink solid;">CN</span>
      <span @dblclick="chgLang(noteitem.id,noteitem.idx)" v-if="noteitem.lang==2" style="font-size:12px;color:white;background-color: blue;border:3px blue solid;">EN</span>
      {{noteitem['student.plain']?'🅿️':''}}
      {{noteitem['student.other']?'🌙':''}}
      &nbsp;<b>R:</b>{{noteitem.created_at?new Date(noteitem.created_at).toLocaleDateString():''}}
      &nbsp;<b>B:</b>{{noteitem.buy_at?new Date(noteitem.buy_at).toLocaleDateString().replace(/01\/01\/1980/,' --'):''}}
      &nbsp;<b>V:</b>{{noteitem.visit_at?new Date(noteitem.visit_at).toLocaleDateString():''}}
      &nbsp;<b>P:{{noteitem.credit}}</b>
      &nbsp;<span @dblclick="chgNote(1)" style="font-size:13px;color:gray;background-color: #fad7a0;border:3px #fad7a0 solid;"> ♣</span>
      &nbsp;<span @dblclick="chgNote(2)" style="font-size:13px;color:gray;background-color: #fad7a0;border:3px #fad7a0 solid;">】</span> 
      <br><span style="background-color: #ddd;">{{noteitem.name}}</span>&nbsp;
      {{noteitem.email}}&nbsp;
      <span style="background-color: #ddd;">{{noteitem.mobile}}</span>
      {{noteitem.address}} {{noteitem.suburb}}
      <br><span style="background-color: #ddd;">{{noteitem['student.name']}}</span>
      {{noteitem['student.block']==4?'⛔':noteitem['student.block']==3?'⭕':noteitem['student.block']==2?'🔒🔒':noteitem['student.block']==1?'🔒':''}}
      {{noteitem['student.alert']>2?('❗'+arrNums[noteitem['student.alert']]+'❗'):noteitem['student.alert']==2?'❗❗':noteitem['student.alert']==1?'❗':noteitem['student.alert']==(-1)?'🌵':''}}
      <span @dblclick="mock(noteitem['student.fbuid'])">{{noteitem['student.loginname']}}</span>
      {{Math.floor(noteitem['student.pall']/100%10)>0?' ❰pc'+Math.floor(noteitem['student.pall']/100%10)+'❱':''}}
      {{Math.floor(noteitem['student.pall']/1000%10)>0?' ❰oc'+Math.floor(noteitem['student.pall']/1000%10)+'❱':''}}
      {{Math.floor(noteitem['student.pall']/10000%10)>0?' ❰ps'+Math.floor(noteitem['student.pall']/10000%10)+'❱':''}}
      {{Math.floor(noteitem['student.pall']/100000%10)>0?' ❰ss'+Math.floor(noteitem['student.pall']/100000%10)+'❱':''}}
      {{Math.floor(noteitem['student.pall']%100)>0?' ❰rt'+Math.floor(noteitem['student.pall']%100)+'❱':''}}
      &nbsp;&nbsp;<span style="background-color: #ddd;">{{noteitem['student.gender']}}</span>
      &nbsp;&nbsp;<b>Yr</b>{{noteitem['student.grade']}}
      <span v-if="noteitem['student.doidt']==2">⛺</span>
      &nbsp;&nbsp;<b>T:</b>{{new Date(noteitem['student.tested_at']).toLocaleDateString()}}
      &nbsp;&nbsp;<b>⭐{{noteitem['student.score']}} / {{noteitem['student.avg5']}}</b>    
      <a-textarea v-model:value="noteitem.notes" :rows="8" style="margin-bottom:10px;resize: none;" />
      <a-button size="small" type="primary" @click="upNotes(1)" style="margin-right:10px;">Update</a-button>
      <a-button size="small" @click="upNotes(0)" style="margin-right:10px;">Cancel</a-button>
      <span v-if="Object.keys(location).length" style="color: #666;">&nbsp;&nbsp;<b>IP</b>: {{ noteitem.ip }} - {{ location.city }} -  
      {{ location.country_name=='Australia'?location.region_code:location.country_name}} - {{ location.org }}</span> 
    </div>      
    <div class="pageline">
      <div @click="onChange(item)" v-for="(item) in arrPageNum" :key="'pg'+item" 
       :class="(currentpage==item)?'pagetab bk_selpg':'pagetab'">{{item}}
      </div>
    </div>
    <div class="pageline">
      <a-input v-model:value="iptsearch" size="small" style="width: 120px;margin-right:10px;"/>
      <a-button size="small" type="primary" @click="search(1)" style="margin-right:10px;">Search</a-button>
      <a-button size="small" @click="search(0)" style="margin-right:10px;">Clear</a-button>
      <a-button size="small" danger shape="round" @click="getOrderList()" style="margin-left:20px;">Reload</a-button>
    </div>
    
    <div class="divpromo" v-show="showpromo">
      <b>用</b>{{promoitem['student.loginname']}}<b>码</b>
      {{Math.floor(promoitem['student.pall']/100%10)>0?' ❰pc'+Math.floor(promoitem['student.pall']/100%10)+'❱':''}}
      {{Math.floor(promoitem['student.pall']/1000%10)>0?' ❰oc'+Math.floor(promoitem['student.pall']/1000%10)+'❱':''}}
      {{Math.floor(promoitem['student.pall']/10000%10)>0?' ❰ps'+Math.floor(promoitem['student.pall']/10000%10)+'❱':''}}
      {{Math.floor(promoitem['student.pall']/100000%10)>0?' ❰ss'+Math.floor(promoitem['student.pall']/100000%10)+'❱':''}}
      {{Math.floor(promoitem['student.pall']%100)>0?' ❰rt'+Math.floor(promoitem['student.pall']%100)+'❱':''}}
      &nbsp;&nbsp;<b>Yr</b>{{promoitem['student.grade']}}
      &nbsp;&nbsp;<b>T:</b>{{new Date(promoitem['student.tested_at']).toLocaleDateString()}}
      &nbsp;&nbsp;<b>⭐{{promoitem['student.score']}} / {{promoitem['student.avg5']}}</b>    
      <br>{{promoitem.suburb}}
      &nbsp;<span @dblclick="chgNote(1)" style="font-size:13px;color:gray;background-color: #fad7a0;border:3px #fad7a0 solid;"> ♣</span>
      &nbsp;<span @dblclick="chgNote(2)" style="font-size:13px;color:gray;background-color: #fad7a0;border:3px #fad7a0 solid;">】</span> 
      &nbsp;&nbsp;<b>Visit:</b><u>{{promoitem.visit_at?new Date(promoitem.visit_at).toLocaleDateString():''}}
      {{promoitem.visit_at?new Date(promoitem.visit_at).toLocaleTimeString():''}}</u>
      &nbsp;&nbsp;<b>Point: {{promoitem.credit}}</b>
      <br>{{promoitem.notes}}
    </div>  

    <table class="tb88">
      <tr style="text-align:center;font-size:15px;font-weight:bold;">
        <td></td><td>ID</td><td>Order Time</td><td>User Email</td><td>S-Username</td><td>&nbsp;Yr&nbsp;</td><td>Pack</td><td>Promo</td><td>Price</td><td>Points</td><td>Pay</td><td>Status</td><td>Act</td>
      </tr>
      <tr :class="item.status==1?'cl_normal':item.status==2?'cl_done':'cl_cancel'" v-for="(item,index) in ordered" :key="'or'+index">
        <td>{{(index%5==4 && index<99)?index+1:''}}</td> 
        <td>A{{item.id}}</td>        
        <td>{{new Date(item.created_at).toLocaleDateString()}}&nbsp; {{new Date(item.created_at).toLocaleTimeString()}}</td>
        <td><span class="clickspan" @click="showNotes(item.loginname)">{{item['user.email']}}</span>
          <span style="color:#bbb;" :title="item['user.notes']" class="clickspan" @click="showNotes(item.loginname)">{{item['user.notes']?item['user.notes'].substring(0,8):''}}</span></td>
        <td>{{item['student.alert']>2?('❗'+arrNums[item['student.alert']]+'❗'):item['student.alert']==2?'❗❗':item['student.alert']==1?'❗':''}}
        <span class="clickspan" @click="showNotes(item.loginname)">{{item.loginname}}
        {{Math.floor(item['student.pall']/100%10)>0?' ❰pc'+Math.floor(item['student.pall']/100%10)+'❱':''}}
        {{Math.floor(item['student.pall']/1000%10)>0?' ❰oc'+Math.floor(item['student.pall']/1000%10)+'❱':''}}
        {{Math.floor(item['student.pall']/10000%10)>0?' ❰ps'+Math.floor(item['student.pall']/10000%10)+'❱':''}}
        {{Math.floor(item['student.pall']/100000%10)>0?' ❰ss'+Math.floor(item['student.pall']/100000%10)+'❱':''}}
        {{Math.floor(item['student.pall']%100)>0?' ❰rt'+Math.floor(item['student.pall']%100)+'❱':''}}</span>
        <br v-if="chkrtkname==item.loginname">
        <span v-if="chkrtkname==item.loginname">{{showrtk}}</span>
        </td>
        <td>
          {{item['student.grade']}}
          <span v-if="copyed==item.id " style="background-color: lightgreen;padding: 3px;">♾</span>
          <span v-if="!copyed && currentpage==1 && item.pack_id!=306 && item.status==2 && (Math.floor(item['student.pall']/100%10)+Math.floor(item['student.pall']/1000%10+Math.floor(item['student.pall']/10000%10)+Math.floor(item['student.pall']/100000%10))==1)" @click="copyit(item['student.grade'],item.pack_id,item.loginname,item.id)" style="background-color: orange;padding: 3px;">©</span>
        </td>
        <td>
          {{item.pack_name.replace(' -- Pack of 10 Sets','').replace(' Trial Test','').replace('Selective','SS').replace('Smart Retake Package','Rtk')}}
          <span v-if='item.pack_id==306 && item.status==1' @click="chkretakes(item.loginname)">❓</span>
        </td>
        <td>
          <span @click="promochk(item.promocode)">{{item.promocode}}</span>
          <a-popconfirm v-if="item.status==1 && item.promocode && item.promocode.slice(-1)!='X'" placement="left" ok-text="Yes" cancel-text="No" @confirm="xpromo(item.id)">
            <template v-slot:title>
              <p>Fix promo code?</p>
            </template>
            <span style="background-color: orange;padding: 3px;">☒</span>
          </a-popconfirm>
        </td>
        <td>{{item.price}}</td>
        <td>{{item.points}}</td>
        <td>{{item.amount}}</td>
        <td>{{item.status==1?'Waiting':item.status==2?'Success':'Canceled'}}</td>
        <td>
          <a-popconfirm v-if="item.status==1" placement="left" ok-text="Yes" cancel-text="No" @confirm="acter(item.id,2)">
            <template v-slot:title>
              <p>Are you sure the payment of order {{item.id}} has been received?</p>
            </template>
            <span class="act_botton">{{windowWidth>600?'Confirm':'C'}}</span>
          </a-popconfirm>
          <br v-if="windowWidth<600">
          <a-popconfirm v-if="item.status==1 && item.pack_id!=306 && windowWidth>=600" placement="left" ok-text="Yes" cancel-text="No" @confirm="acter(item.id,4)">
            <template v-slot:title>
              <p>Is the payment of order {{item.id}} has been received by email?</p>
            </template>
            <span class="act_botton">W</span>
          </a-popconfirm>
          <br v-if="windowWidth<600">        
          <a-popconfirm v-if="item.status==1" placement="left" ok-text="Yes" cancel-text="No" @confirm="acter(item.id,3)">
            <template v-slot:title>
              <p>Cancel order {{item.id}}?</p>
            </template>
            <span class="act_botton">✖</span>
          </a-popconfirm>
          <a-popconfirm v-if="item.status==4" placement="left" ok-text="Yes" cancel-text="No" @confirm="acter(item.id,1)">
            <template v-slot:title>
              <p>Restore order {{item.id}}?</p>
            </template>
            <span>R</span>
          </a-popconfirm>
        </td>
      </tr>    
    </table>
    <div class="pageline">
      <div @click="onChange(item)" v-for="(item) in arrPageNum" :key="'pg'+item" 
       :class="(currentpage==item)?'pagetab bk_selpg':'pagetab'">{{item}}
      </div>
    </div>
  </div>
</template>

<script>
import { Geter,Puter } from '@/utils/request.js';
import swal from 'sweetalert2';

export default {
  name:'ordercheck',
  data () {
    return {
      ordered: [],
      totalpages: 1,
      currentpage: 1,
      arrPageNum: [],
      iptsearch: '',
      arrNums: ['','①', '②', '③', '④', '⑤', '⑥', '⑦', '⑧', '⑨', '⑩', '⑪', '⑫', '⑬', '⑭', '⑮'],
      shownotes: false,
      noteitem: {},
      showpromo: false,
      promoitem: {},
      location: {},
      windowWidth: document.documentElement.clientWidth, //实时屏幕宽度
      copyed: 0,
      showrtk: '',
      chkrtkname: '',
    };
  },

  components: {},

  computed: {},

  beforeMount() {},

  mounted() {
    this.getOrderList();
    window.onresize = () => {
      return (() => {
        let fullWidth = document.documentElement.clientWidth;
        this.windowWidth = fullWidth; 
      })();
    };
  },

  methods: {
    promochk(promo){
      this.iptsearch = promo.replace('R','@').replace('X','')
    },

    async getOrderList(topg=0,sch='') {
      try {
        let response = await Geter(`orderlist`,{pg:topg,sch:sch});
        if (Object.keys(response.data).length && response.data!='Access Denied'){
          console.log(response.data)
          this.ordered = response.data.rows;
          this.totalpages = Math.ceil(response.data.count/100);
          this.arrPageNum = [];
          this.currentpage = 1 + parseInt(response.data.pg);
          this.copyed = 0;
          for(let i=1;i<=this.totalpages;i++){
            if(Math.abs(this.currentpage-i)<6){
              this.arrPageNum.push(i);
            }else if(i%10==0){
              this.arrPageNum.push(i);
            }else if(i==this.totalpages){
              this.arrPageNum.push(i);
            }else if(i<10){
              this.arrPageNum.push(i);
            }
          }
        }
      }catch(e) {
        console.log(e);
      }
    },

    async mock(stu){
      console.log(stu);
      try {
        let response = await Geter(`mockstudent`,{st:stu});
        if (response.data==201){
          swal.fire({text: 'Successfully imitating the student!',
          timer: 3000,
          showConfirmButton: false});
        }
      }catch(e) {
        console.log(e);
      }
    },

    onChange(topg) {
      this.getOrderList(topg);
    },

    async acter(id,tp) {
      let response = await Puter(`orderact`,{id:id,tp:tp});
      if (response.data!='404'){
        this.getOrderList(this.currentpage,this.iptsearch);       
      }  
    },

    search(code){
      if(code==0){
        this.valuechk = 's';
        this.iptsearch = '';
        this.showpromo = false;
      }
      this.iptsearch = this.iptsearch.trim();
      this.getOrderList(0,this.iptsearch);
      if(this.iptsearch.slice(0,1)=='@'){
        this.showPromo(this.iptsearch.slice(1));
      }
    },

    async showNotes(loginname){
      this.location = {}
      if(loginname){
        try {
          let response = await Geter('shownote',{ln:loginname});
          if(response && response.data.code==201){
            this.shownotes = true;
            this.noteitem = response.data.item;
            let ipAddress = this.noteitem.visit_ip.split(',')[0];
            this.noteitem.ip = ipAddress.substring(0,20);
            let res = await Geter(`https://ipapi.co/${ipAddress}/json/`)
            this.location = res.data;
          }
        }catch(e) {
          console.log(e);
        }
      }
    },

    async showPromo(promo){
      if(promo){
        try {
          let response = await Geter('showpromo',{pr:promo});
          if(response && response.data.code==201){
            this.showpromo = true;
            this.promoitem = response.data.item;
          }
        }catch(e) {
          console.log(e);
        }
      }
    },

    async upNotes(type){
      if(type){
        try {
          let response = await Puter('upnote',{id:this.noteitem.id,notes:this.noteitem.notes.trim()});
          if(response && response.data==201){
            this.shownotes = false;
            this.noteitem = {};
            this.getUserList(this.currentpage,this.valuechk,this.iptsearch);
          }else{
            swal.fire({text: 'Update failed! To clear please type "Del".',
            timer: 3000,
            showConfirmButton: false});
          }
        }catch(e) {
          console.log(e);
        }
        this.getOrderList(this.currentpage,this.iptsearch);
      }else{
        this.shownotes = false;
        this.noteitem = {};
      }
    },

    chgNote(n) {
      if(n==1){
        this.noteitem.notes = '♣' + this.noteitem.notes;
      }else if(n==2){
        this.noteitem.notes = this.noteitem.notes + '【】';
      }
    },

    async chgLang(id,idx){
      try {
        let response = await Geter('chglang',{id:this.noteitem.id,notes:this.noteitem.notes.trim()});
        if(response && response.data==201){
          this.noteitem.lang = 3 - this.noteitem.lang;
          this.users[idx].lang = this.noteitem.lang;
          //this.getUserList(this.currentpage,this.valuechk,this.iptsearch);
        }
      }catch(e) {
        console.log(e);
      }
    },

    async xpromo(ordernum){
      try {
        let response = await Geter(`xpromo`,{od:ordernum});
        this.getOrderList(this.currentpage,this.iptsearch);  
      }catch(e) {
        console.log(e);
      }
    },

    async chkretakes(loginname){
      if(loginname){
        this.showrtk = '... ...';
        this.chkrtkname = loginname;
        try {
          let response = await Geter(`chkretakes`,{ln:loginname,qk:1});
          if (Object.keys(response.data).length){
            this.showrtk = response.data.msg;            
          }
        }catch(e) {
          console.log(e);
        }
      }
    },

    copyit(grade,packid,login,order) {
      let value="";
      let date = new Date;
      if(grade==6 && packid=='122'){
        value = value + date.getFullYear() + "SS  " + login + "  A" + order;
      }else if(grade==5 && packid=='122'){
        value = value + (parseInt(date.getFullYear()) + 1) + "SS  " + login + "  A" + order;
      }else if(grade==5 && packid=='402'){
        value = value + (parseInt(date.getFullYear()) + 1) + "PS  " + login + "  A" + order;
      }else if(grade==4 && packid=='402'){
        value = value + (parseInt(date.getFullYear()) + 2) + "PS  " + login + "  A" + order;
      }else if(grade==4 && packid=='213'){
        value = value + date.getFullYear() + "OC  " + login + "  A" + order;
      }else if(grade==4 && packid=='413'){
        value = value + date.getFullYear() + "PC  " + login + "  A" + order;
      }else if(grade==3 && packid=='213'){
        value = value + (parseInt(date.getFullYear()) + 1) + "OC  " + login + "  A" + order;
      }else if(grade==3 && packid=='413'){
        value = value + (parseInt(date.getFullYear()) + 1) + "PC  " + login + "  A" + order;
      }
      let oInput = document.createElement("input");
      oInput.value = value.slice(2) + "   ";
      document.body.appendChild(oInput);
      oInput.select();
      document.execCommand("Copy");
      oInput.remove();
      this.copyed = order;
    },

  },
}

</script>
<style scoped>
#usernotes{
  position: fixed;
  z-index: 99;
  left: 10%;
  top: 30%;
  width: 580px;
  height: 340px; 
  margin: 8px 8px;
  padding: 16px;
  overflow: none; 
  border-width: 1px;
  border-style: solid; 
  border-color: lightgray;   
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.258824),0 2px 6px 0 rgba(0,0,0,0.156863);
  background-color: white;
  text-align: left;
}
.clickspan:hover{
  cursor:pointer;
}
.orderlist{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 20px;
}
.pageline{
  margin: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-flow: wrap;
}
.pagetab{
  margin: 0 4px;
  width: 20px;
  height: 20px;
  text-align: center;
  border: 1px solid #999;  
  border-radius: 3px;  
}
.pagetab:hover{
  cursor:pointer;
  opacity: 0.85;
  box-shadow: 1px 1px 0 1px #666,
              1px 1px 2px 1px #999;
}
.bk_selpg{
  background-color: #FFD54F;
}

.divpromo{
  width: 95%;  
  border-width: 1px;
  border-style: solid; 
  border-color: black;   
  text-align: left;
  white-space: pre-wrap;
}
.tb88  {
  width: 95%;
  margin: 24px;
  word-break: break-all;
}
.tb88 tr td{
  border: 1px solid black;
}
.cl_cancel{
  color: #F48FB1;
}
.cl_done{
  color: #4CAF50;
}
.cl_normal{
  color: black;
}

.act_botton {
  font-size: 13px;
  line-height: 18px;
  border-width: 1px;
  border-style: solid;    
  border-color: #666;   
  border-radius: 16px;
  padding: 2px 4px;
  margin: 2px;
  color: white;
  text-shadow: 0 0 1px #000;
  background: #7d9dda;
  white-space: nowrap;
}
.act_botton:hover{
  cursor:pointer;
  opacity: 0.85;
  box-shadow:1px 1px 2px #666;
}

@media screen and (max-width: 1000px) {
  *,textarea,button,span {
    font-size: 12px;
  }
  #usernotes{
    left: 1%;
    top: 25%;
    font-size: 10px;
    width: 390px;
    height: 300px; 
    padding: 8px;
  }
}
</style>